/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const acceptInvitation = /* GraphQL */ `
  mutation AcceptInvitation($workspace: String!) {
    acceptInvitation(workspace: $workspace) {
      error
      message
      object
      status
    }
  }
`;
export const addContactToDirectory = /* GraphQL */ `
  mutation AddContactToDirectory(
    $contactDirectory: ContactDirectoryInput!
    $workspace: String!
  ) {
    addContactToDirectory(
      contactDirectory: $contactDirectory
      workspace: $workspace
    ) {
      Directory_Category_Index
      Directory_Workspace_Index
      Time_Index
      address {
        city
        country
        number
        state
        street
        type
        zipCode
      }
      agent
      contactName
      contactOwnerType
      contactUserType
      createdAt
      createdBy
      email
      fax
      fixedLineNumber
      id
      isEnabled
      mobileNumber
      phoneNumber
      teamName
    }
  }
`;
export const addCreditCard = /* GraphQL */ `
  mutation AddCreditCard(
    $provider: String!
    $token: String!
    $workspace: String!
  ) {
    addCreditCard(provider: $provider, token: $token, workspace: $workspace) {
      error
      message
      object
      status
    }
  }
`;
export const addCustomRolePermission = /* GraphQL */ `
  mutation AddCustomRolePermission(
    $description: String
    $destroy: Boolean!
    $list: Boolean!
    $read: Boolean!
    $resource: String!
    $role: String!
    $workspace: String!
    $write: Boolean!
  ) {
    addCustomRolePermission(
      description: $description
      destroy: $destroy
      list: $list
      read: $read
      resource: $resource
      role: $role
      workspace: $workspace
      write: $write
    ) {
      description
      destroy
      list
      read
      resource
      write
    }
  }
`;
export const addCustomRolePermissions = /* GraphQL */ `
  mutation AddCustomRolePermissions(
    $permissions: [SystemRolePermissionInput]
    $role: String!
    $workspace: String!
  ) {
    addCustomRolePermissions(
      permissions: $permissions
      role: $role
      workspace: $workspace
    ) {
      error
      message
      object
      status
    }
  }
`;
export const addCustomerContact = /* GraphQL */ `
  mutation AddCustomerContact(
    $contact: CustomerContactsInput!
    $user: String!
    $workspace: String!
  ) {
    addCustomerContact(contact: $contact, user: $user, workspace: $workspace) {
      Customer_Contact_Index
      contact
      createdAt
      customer
      isPrimary
      isValid
      type
      workspace
    }
  }
`;
export const addCustomerTag = /* GraphQL */ `
  mutation AddCustomerTag($tag: String, $user: String!, $workspace: String!) {
    addCustomerTag(tag: $tag, user: $user, workspace: $workspace) {
      Customer_User_Index
      Customer_Workspace_Index
      active
      avatar
      birthDay
      contacts {
        Customer_Contact_Index
        contact
        createdAt
        customer
        isPrimary
        isValid
        type
        workspace
      }
      createdAt
      firstName
      gender
      id
      lastInteraction {
        Interaction_Customer_Index
        Interaction_Handled_By_Index
        Interaction_Status_Index
        Interaction_Time_Index
        Interaction_Type_Time_Index
        Interaction_Workspace_Customer_Index
        Interaction_Workspace_Index
        cdrLegs {
          duration
          from
          hangupReason
          legId
          to
        }
        channel
        createdAt
        customer
        direction
        disposition
        entry
        from {
          display
          id
          type
        }
        handledBy {
          handledAs
          handledAt
          id
        }
        id
        key
        lastHandledBy {
          handledAs
          handledAt
          id
        }
        message
        myCdrLeg {
          duration
          from
          hangupReason
          legId
          to
        }
        otherData
        serverTimeStamp
        sessionId
        status
        tags
        to {
          display
          id
          type
        }
        type
        updatedAt
        updatedBy
        workspace
      }
      lastName
      locale
      name
      ssn
      tags
      title
      workspace
    }
  }
`;
export const addFlowNodeMeta = /* GraphQL */ `
  mutation AddFlowNodeMeta($nodeMeta: FlowNodeMetaInput!) {
    addFlowNodeMeta(nodeMeta: $nodeMeta) {
      category
      data
      design
      entry
      key
      name
    }
  }
`;
export const addInteractionDisposition = /* GraphQL */ `
  mutation AddInteractionDisposition(
    $disposition: String!
    $interactionId: String!
    $workspace: String!
  ) {
    addInteractionDisposition(
      disposition: $disposition
      interactionId: $interactionId
      workspace: $workspace
    ) {
      error
      message
      object
      status
    }
  }
`;
export const addInteractionEvent = /* GraphQL */ `
  mutation AddInteractionEvent(
    $event: InteractionEventInput!
    $interactionId: String!
    $workspace: String!
  ) {
    addInteractionEvent(
      event: $event
      interactionId: $interactionId
      workspace: $workspace
    ) {
      createdAt
      entry
      eventCode
      eventData
      eventDisplay
      eventName
      eventType
      id
      key
      sessionId
      timeStamp
    }
  }
`;
export const addInteractionTag = /* GraphQL */ `
  mutation AddInteractionTag(
    $interactionId: String!
    $tag: String
    $workspace: String!
  ) {
    addInteractionTag(
      interactionId: $interactionId
      tag: $tag
      workspace: $workspace
    ) {
      Interaction_Customer_Index
      Interaction_Handled_By_Index
      Interaction_Status_Index
      Interaction_Time_Index
      Interaction_Type_Time_Index
      Interaction_Workspace_Customer_Index
      Interaction_Workspace_Index
      cdrLegs {
        duration
        from
        hangupReason
        legId
        to
      }
      channel
      createdAt
      customer
      direction
      disposition
      entry
      from {
        display
        id
        type
      }
      handledBy {
        handledAs
        handledAt
        id
      }
      id
      key
      lastHandledBy {
        handledAs
        handledAt
        id
      }
      message
      myCdrLeg {
        duration
        from
        hangupReason
        legId
        to
      }
      otherData
      serverTimeStamp
      sessionId
      status
      tags
      to {
        display
        id
        type
      }
      type
      updatedAt
      updatedBy
      workspace
    }
  }
`;
export const addNumberToTeam = /* GraphQL */ `
  mutation AddNumberToTeam(
    $name: String!
    $number: String!
    $workspace: String!
  ) {
    addNumberToTeam(name: $name, number: $number, workspace: $workspace) {
      createdAt
      createdBy
      description
      enabled
      entry
      key
      members {
        Team_Member_MYTeam_Index
        Team_Member_Team_Name
        createdAt
        entry
        key
        profile {
          createdAt
          entry
          firstName
          key
          lastName
          poolId
          userId
          userName
        }
        role
        teamName
        user
      }
      name
      numbers
      setting
      workspace
    }
  }
`;
export const addRecentDial = /* GraphQL */ `
  mutation AddRecentDial($recentDial: RecentDialInput!, $workspace: String!) {
    addRecentDial(recentDial: $recentDial, workspace: $workspace) {
      RecentDial_Handled_By_Index
      RecentDial_Time_Index
      callerName
      contactUserType
      createdAt
      data
      dialNumber
      id
      operation
      operationStatus
      recentDialId
      workspace
    }
  }
`;
export const addSystemRolePermission = /* GraphQL */ `
  mutation AddSystemRolePermission(
    $destroy: Boolean!
    $list: Boolean!
    $read: Boolean!
    $resource: String!
    $role: String!
    $write: Boolean!
  ) {
    addSystemRolePermission(
      destroy: $destroy
      list: $list
      read: $read
      resource: $resource
      role: $role
      write: $write
    ) {
      error
      message
      object
      status
    }
  }
`;
export const addSystemRolePermissions = /* GraphQL */ `
  mutation AddSystemRolePermissions(
    $permissions: [SystemRolePermissionInput]
    $role: String!
  ) {
    addSystemRolePermissions(permissions: $permissions, role: $role) {
      error
      message
      object
      status
    }
  }
`;
export const addUsersToTeam = /* GraphQL */ `
  mutation AddUsersToTeam(
    $members: [WorkspaceTeamMemberInput]!
    $name: String!
    $workspace: String!
  ) {
    addUsersToTeam(members: $members, name: $name, workspace: $workspace) {
      error
      message
      object
      status
    }
  }
`;
export const assignPackage = /* GraphQL */ `
  mutation AssignPackage(
    $couponCode: String
    $newPackageCode: String!
    $provider: String!
    $quantity: Int
    $rechargeAmount: Int
    $recurringInterval: String!
    $thresholdAmount: Int
    $type: String!
    $workspace: String!
  ) {
    assignPackage(
      couponCode: $couponCode
      newPackageCode: $newPackageCode
      provider: $provider
      quantity: $quantity
      rechargeAmount: $rechargeAmount
      recurringInterval: $recurringInterval
      thresholdAmount: $thresholdAmount
      type: $type
      workspace: $workspace
    ) {
      error
      message
      object
      status
    }
  }
`;
export const callControl = /* GraphQL */ `
  mutation CallControl(
    $command: CallControlCommand!
    $commandQueue: String!
    $data: AWSJSON
    $interactionID: String!
    $workspace: String!
  ) {
    callControl(
      command: $command
      commandQueue: $commandQueue
      data: $data
      interactionID: $interactionID
      workspace: $workspace
    )
  }
`;
export const changeCustomerAddress = /* GraphQL */ `
  mutation ChangeCustomerAddress(
    $address: AddressInput!
    $user: String!
    $workspace: String!
  ) {
    changeCustomerAddress(
      address: $address
      user: $user
      workspace: $workspace
    ) {
      city
      country
      number
      state
      street
      type
      zipCode
    }
  }
`;
export const changePhoneNumberState = /* GraphQL */ `
  mutation ChangePhoneNumberState(
    $number: String!
    $status: String!
    $workspace: String!
  ) {
    changePhoneNumberState(
      number: $number
      status: $status
      workspace: $workspace
    ) {
      error
      message
      object
      status
    }
  }
`;
export const changeRole = /* GraphQL */ `
  mutation ChangeRole($role: String!, $user: String!, $workspace: String!) {
    changeRole(role: $role, user: $user, workspace: $workspace) {
      Member_Role_Index
      Member_Status_Role
      Member_Workspace
      invitedAt
      isActive
      isCustomRole
      joinedAt
      joinedMethod
      profile {
        createdAt
        entry
        firstName
        key
        lastName
        poolId
        userId
        userName
      }
      role
      state
      userId
    }
  }
`;
export const changeRoleState = /* GraphQL */ `
  mutation ChangeRoleState(
    $isActivate: Boolean!
    $user: String!
    $workspace: String!
  ) {
    changeRoleState(
      isActivate: $isActivate
      user: $user
      workspace: $workspace
    ) {
      Member_Role_Index
      Member_Status_Role
      Member_Workspace
      invitedAt
      isActive
      isCustomRole
      joinedAt
      joinedMethod
      profile {
        createdAt
        entry
        firstName
        key
        lastName
        poolId
        userId
        userName
      }
      role
      state
      userId
    }
  }
`;
export const createAbandonCallThreshold = /* GraphQL */ `
  mutation CreateAbandonCallThreshold(
    $team: String!
    $threshold: Int
    $timeSlot: String
    $workspace: String!
  ) {
    createAbandonCallThreshold(
      team: $team
      threshold: $threshold
      timeSlot: $timeSlot
      workspace: $workspace
    ) {
      entry
      isActive
      key
      threshold
      timeSlot
    }
  }
`;
export const createCallRule = /* GraphQL */ `
  mutation CreateCallRule(
    $number: String!
    $rule: CallRuleInput
    $workspace: String!
  ) {
    createCallRule(number: $number, rule: $rule, workspace: $workspace) {
      Rule_Number_Index
      createdAt
      createdBy
      description
      direction
      endTimeIndex
      isActive
      name
      number
      offRuleAction
      offRuleActionData
      ruleAction
      ruleActionData
      startTimeIndex
      timeMode
      type
      workspace
    }
  }
`;
export const createCustomRole = /* GraphQL */ `
  mutation CreateCustomRole(
    $description: String!
    $role: String!
    $workspace: String!
  ) {
    createCustomRole(
      description: $description
      role: $role
      workspace: $workspace
    ) {
      error
      message
      object
      status
    }
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $profile: CustomerProfileInput!
    $workspace: String!
  ) {
    createCustomer(profile: $profile, workspace: $workspace) {
      Customer_User_Index
      Customer_Workspace_Index
      active
      avatar
      birthDay
      contacts {
        Customer_Contact_Index
        contact
        createdAt
        customer
        isPrimary
        isValid
        type
        workspace
      }
      createdAt
      firstName
      gender
      id
      lastInteraction {
        Interaction_Customer_Index
        Interaction_Handled_By_Index
        Interaction_Status_Index
        Interaction_Time_Index
        Interaction_Type_Time_Index
        Interaction_Workspace_Customer_Index
        Interaction_Workspace_Index
        cdrLegs {
          duration
          from
          hangupReason
          legId
          to
        }
        channel
        createdAt
        customer
        direction
        disposition
        entry
        from {
          display
          id
          type
        }
        handledBy {
          handledAs
          handledAt
          id
        }
        id
        key
        lastHandledBy {
          handledAs
          handledAt
          id
        }
        message
        myCdrLeg {
          duration
          from
          hangupReason
          legId
          to
        }
        otherData
        serverTimeStamp
        sessionId
        status
        tags
        to {
          display
          id
          type
        }
        type
        updatedAt
        updatedBy
        workspace
      }
      lastName
      locale
      name
      ssn
      tags
      title
      workspace
    }
  }
`;
export const createFlow = /* GraphQL */ `
  mutation CreateFlow($flow: FlowInput!, $workspace: String!) {
    createFlow(flow: $flow, workspace: $workspace) {
      Flow_Start_Node
      Flow_Workspace_Index
      activeVersion
      createdAt
      createdBy
      description
      entry
      id
      isActive
      key
      name
      type
      url
      version
    }
  }
`;
export const createInteraction = /* GraphQL */ `
  mutation CreateInteraction(
    $interaction: InteractionInput
    $workspace: String!
  ) {
    createInteraction(interaction: $interaction, workspace: $workspace) {
      Interaction_Customer_Index
      Interaction_Handled_By_Index
      Interaction_Status_Index
      Interaction_Time_Index
      Interaction_Type_Time_Index
      Interaction_Workspace_Customer_Index
      Interaction_Workspace_Index
      cdrLegs {
        duration
        from
        hangupReason
        legId
        to
      }
      channel
      createdAt
      customer
      direction
      disposition
      entry
      from {
        display
        id
        type
      }
      handledBy {
        handledAs
        handledAt
        id
      }
      id
      key
      lastHandledBy {
        handledAs
        handledAt
        id
      }
      message
      myCdrLeg {
        duration
        from
        hangupReason
        legId
        to
      }
      otherData
      serverTimeStamp
      sessionId
      status
      tags
      to {
        display
        id
        type
      }
      type
      updatedAt
      updatedBy
      workspace
    }
  }
`;
export const createInteractionNote = /* GraphQL */ `
  mutation CreateInteractionNote(
    $interactionId: String!
    $note: NoteInput
    $workspace: String!
  ) {
    createInteractionNote(
      interactionId: $interactionId
      note: $note
      workspace: $workspace
    ) {
      createdAt
      createdBy
      entry
      id
      key
      message
      noteAttachment {
        contentType
        key
        storage
        type
      }
      updatedAt
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $message: String!
    $priority: String
    $receiver: String!
    $type: String!
    $workspace: String!
  ) {
    createNotification(
      message: $message
      priority: $priority
      receiver: $receiver
      type: $type
      workspace: $workspace
    ) {
      createdAt
      createdBy
      message
      notificationId
      priority
      receiver
      status
      type
    }
  }
`;
export const createSystemRole = /* GraphQL */ `
  mutation CreateSystemRole($description: String!, $role: String!) {
    createSystemRole(description: $description, role: $role) {
      error
      message
      object
      status
    }
  }
`;
export const createTag = /* GraphQL */ `
  mutation CreateTag(
    $description: String
    $isActive: Boolean!
    $name: String!
    $type: String!
    $workspace: String!
  ) {
    createTag(
      description: $description
      isActive: $isActive
      name: $name
      type: $type
      workspace: $workspace
    ) {
      description
      isActive
      name
      type
      workspace
    }
  }
`;
export const createTeam = /* GraphQL */ `
  mutation CreateTeam(
    $description: String
    $name: String!
    $setting: String
    $workspace: String!
  ) {
    createTeam(
      description: $description
      name: $name
      setting: $setting
      workspace: $workspace
    ) {
      createdAt
      createdBy
      description
      enabled
      entry
      key
      members {
        Team_Member_MYTeam_Index
        Team_Member_Team_Name
        createdAt
        entry
        key
        profile {
          createdAt
          entry
          firstName
          key
          lastName
          poolId
          userId
          userName
        }
        role
        teamName
        user
      }
      name
      numbers
      setting
      workspace
    }
  }
`;
export const createWorkspace = /* GraphQL */ `
  mutation CreateWorkspace(
    $description: String
    $timezone: String!
    $workspace: String!
  ) {
    createWorkspace(
      description: $description
      timezone: $timezone
      workspace: $workspace
    ) {
      error
      message
      object
      status
    }
  }
`;
export const deleteAbandonCallThreshold = /* GraphQL */ `
  mutation DeleteAbandonCallThreshold(
    $entry: String!
    $team: String!
    $workspace: String!
  ) {
    deleteAbandonCallThreshold(
      entry: $entry
      team: $team
      workspace: $workspace
    ) {
      entry
      isActive
      key
      threshold
      timeSlot
    }
  }
`;
export const deleteCallRule = /* GraphQL */ `
  mutation DeleteCallRule(
    $isActive: Boolean!
    $number: String!
    $workspace: String!
  ) {
    deleteCallRule(
      isActive: $isActive
      number: $number
      workspace: $workspace
    ) {
      Rule_Number_Index
      createdAt
      createdBy
      description
      direction
      endTimeIndex
      isActive
      name
      number
      offRuleAction
      offRuleActionData
      ruleAction
      ruleActionData
      startTimeIndex
      timeMode
      type
      workspace
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer($user: String!, $workspace: String!) {
    deleteCustomer(user: $user, workspace: $workspace) {
      Customer_User_Index
      Customer_Workspace_Index
      active
      avatar
      birthDay
      contacts {
        Customer_Contact_Index
        contact
        createdAt
        customer
        isPrimary
        isValid
        type
        workspace
      }
      createdAt
      firstName
      gender
      id
      lastInteraction {
        Interaction_Customer_Index
        Interaction_Handled_By_Index
        Interaction_Status_Index
        Interaction_Time_Index
        Interaction_Type_Time_Index
        Interaction_Workspace_Customer_Index
        Interaction_Workspace_Index
        cdrLegs {
          duration
          from
          hangupReason
          legId
          to
        }
        channel
        createdAt
        customer
        direction
        disposition
        entry
        from {
          display
          id
          type
        }
        handledBy {
          handledAs
          handledAt
          id
        }
        id
        key
        lastHandledBy {
          handledAs
          handledAt
          id
        }
        message
        myCdrLeg {
          duration
          from
          hangupReason
          legId
          to
        }
        otherData
        serverTimeStamp
        sessionId
        status
        tags
        to {
          display
          id
          type
        }
        type
        updatedAt
        updatedBy
        workspace
      }
      lastName
      locale
      name
      ssn
      tags
      title
      workspace
    }
  }
`;
export const deleteCustomerContact = /* GraphQL */ `
  mutation DeleteCustomerContact(
    $contact: String!
    $user: String!
    $workspace: String!
  ) {
    deleteCustomerContact(
      contact: $contact
      user: $user
      workspace: $workspace
    ) {
      Customer_Contact_Index
      contact
      createdAt
      customer
      isPrimary
      isValid
      type
      workspace
    }
  }
`;
export const deleteCustomerTag = /* GraphQL */ `
  mutation DeleteCustomerTag(
    $tag: String
    $user: String!
    $workspace: String!
  ) {
    deleteCustomerTag(tag: $tag, user: $user, workspace: $workspace) {
      Customer_User_Index
      Customer_Workspace_Index
      active
      avatar
      birthDay
      contacts {
        Customer_Contact_Index
        contact
        createdAt
        customer
        isPrimary
        isValid
        type
        workspace
      }
      createdAt
      firstName
      gender
      id
      lastInteraction {
        Interaction_Customer_Index
        Interaction_Handled_By_Index
        Interaction_Status_Index
        Interaction_Time_Index
        Interaction_Type_Time_Index
        Interaction_Workspace_Customer_Index
        Interaction_Workspace_Index
        cdrLegs {
          duration
          from
          hangupReason
          legId
          to
        }
        channel
        createdAt
        customer
        direction
        disposition
        entry
        from {
          display
          id
          type
        }
        handledBy {
          handledAs
          handledAt
          id
        }
        id
        key
        lastHandledBy {
          handledAs
          handledAt
          id
        }
        message
        myCdrLeg {
          duration
          from
          hangupReason
          legId
          to
        }
        otherData
        serverTimeStamp
        sessionId
        status
        tags
        to {
          display
          id
          type
        }
        type
        updatedAt
        updatedBy
        workspace
      }
      lastName
      locale
      name
      ssn
      tags
      title
      workspace
    }
  }
`;
export const deleteFlow = /* GraphQL */ `
  mutation DeleteFlow(
    $flowId: String!
    $version: String!
    $workspace: String!
  ) {
    deleteFlow(flowId: $flowId, version: $version, workspace: $workspace) {
      Flow_Start_Node
      Flow_Workspace_Index
      activeVersion
      createdAt
      createdBy
      description
      entry
      id
      isActive
      key
      name
      type
      url
      version
    }
  }
`;
export const deleteFlowMeta = /* GraphQL */ `
  mutation DeleteFlowMeta($nodeMeta: FlowNodeMetaInput!) {
    deleteFlowMeta(nodeMeta: $nodeMeta) {
      category
      data
      design
      entry
      key
      name
    }
  }
`;
export const deleteRole = /* GraphQL */ `
  mutation DeleteRole($role: String!, $workspace: String!) {
    deleteRole(role: $role, workspace: $workspace) {
      Member_Role_Index
      Member_Status_Role
      Member_Workspace
      invitedAt
      isActive
      isCustomRole
      joinedAt
      joinedMethod
      profile {
        createdAt
        entry
        firstName
        key
        lastName
        poolId
        userId
        userName
      }
      role
      state
      userId
    }
  }
`;
export const deleteS3Item = /* GraphQL */ `
  mutation DeleteS3Item(
    $key: String
    $owner: String
    $type: String
    $workspace: String
  ) {
    deleteS3Item(key: $key, owner: $owner, type: $type, workspace: $workspace)
  }
`;
export const deleteTag = /* GraphQL */ `
  mutation DeleteTag($name: String!, $workspace: String!) {
    deleteTag(name: $name, workspace: $workspace) {
      description
      isActive
      name
      type
      workspace
    }
  }
`;
export const deleteTeam = /* GraphQL */ `
  mutation DeleteTeam($name: String!, $workspace: String!) {
    deleteTeam(name: $name, workspace: $workspace) {
      createdAt
      createdBy
      description
      enabled
      entry
      key
      members {
        Team_Member_MYTeam_Index
        Team_Member_Team_Name
        createdAt
        entry
        key
        profile {
          createdAt
          entry
          firstName
          key
          lastName
          poolId
          userId
          userName
        }
        role
        teamName
        user
      }
      name
      numbers
      setting
      workspace
    }
  }
`;
export const disableWorkspaceRole = /* GraphQL */ `
  mutation DisableWorkspaceRole(
    $isSystem: Boolean!
    $role: String
    $workspace: String!
  ) {
    disableWorkspaceRole(
      isSystem: $isSystem
      role: $role
      workspace: $workspace
    ) {
      address {
        city
        country
        number
        state
        street
        type
        zipCode
      }
      avatar
      createdAt
      custom_roles
      description
      enabled
      entry
      key
      owner
      ownerId
      setting
      system_roles
      timezone
      updatedAt
      website
    }
  }
`;
export const enableWorkspaceRole = /* GraphQL */ `
  mutation EnableWorkspaceRole(
    $isEnabled: Boolean!
    $isSystem: Boolean!
    $role: String
    $workspace: String!
  ) {
    enableWorkspaceRole(
      isEnabled: $isEnabled
      isSystem: $isSystem
      role: $role
      workspace: $workspace
    ) {
      error
      message
      object
      status
    }
  }
`;
export const endInteraction = /* GraphQL */ `
  mutation EndInteraction($interactionId: String!, $workspace: String!) {
    endInteraction(interactionId: $interactionId, workspace: $workspace) {
      Interaction_Customer_Index
      Interaction_Handled_By_Index
      Interaction_Status_Index
      Interaction_Time_Index
      Interaction_Type_Time_Index
      Interaction_Workspace_Customer_Index
      Interaction_Workspace_Index
      cdrLegs {
        duration
        from
        hangupReason
        legId
        to
      }
      channel
      createdAt
      customer
      direction
      disposition
      entry
      from {
        display
        id
        type
      }
      handledBy {
        handledAs
        handledAt
        id
      }
      id
      key
      lastHandledBy {
        handledAs
        handledAt
        id
      }
      message
      myCdrLeg {
        duration
        from
        hangupReason
        legId
        to
      }
      otherData
      serverTimeStamp
      sessionId
      status
      tags
      to {
        display
        id
        type
      }
      type
      updatedAt
      updatedBy
      workspace
    }
  }
`;
export const getTag = /* GraphQL */ `
  mutation GetTag($name: String!, $workspace: String!) {
    getTag(name: $name, workspace: $workspace) {
      description
      isActive
      name
      type
      workspace
    }
  }
`;
export const getUploadUrl = /* GraphQL */ `
  mutation GetUploadUrl(
    $action: String!
    $contentType: String!
    $display: String!
    $key: String
    $owner: String!
    $type: String!
    $workspace: String!
  ) {
    getUploadUrl(
      action: $action
      contentType: $contentType
      display: $display
      key: $key
      owner: $owner
      type: $type
      workspace: $workspace
    )
  }
`;
export const getUploadUrlInternal = /* GraphQL */ `
  mutation GetUploadUrlInternal(
    $action: String!
    $contentType: String!
    $display: String!
    $key: String
    $owner: String!
    $type: String!
    $workspace: String!
  ) {
    getUploadUrlInternal(
      action: $action
      contentType: $contentType
      display: $display
      key: $key
      owner: $owner
      type: $type
      workspace: $workspace
    )
  }
`;
export const inviteUser = /* GraphQL */ `
  mutation InviteUser(
    $message: String!
    $role: String!
    $user: String!
    $users: [String]
    $workspace: String!
  ) {
    inviteUser(
      message: $message
      role: $role
      user: $user
      users: $users
      workspace: $workspace
    ) {
      error
      message
      object
      status
    }
  }
`;
export const moveInteraction = /* GraphQL */ `
  mutation MoveInteraction(
    $contact: CustomerContactsInput
    $from: String
    $sessionId: String!
    $to: String!
    $workspace: String!
  ) {
    moveInteraction(
      contact: $contact
      from: $from
      sessionId: $sessionId
      to: $to
      workspace: $workspace
    ) {
      Interaction_Customer_Index
      Interaction_Handled_By_Index
      Interaction_Status_Index
      Interaction_Time_Index
      Interaction_Type_Time_Index
      Interaction_Workspace_Customer_Index
      Interaction_Workspace_Index
      cdrLegs {
        duration
        from
        hangupReason
        legId
        to
      }
      channel
      createdAt
      customer
      direction
      disposition
      entry
      from {
        display
        id
        type
      }
      handledBy {
        handledAs
        handledAt
        id
      }
      id
      key
      lastHandledBy {
        handledAs
        handledAt
        id
      }
      message
      myCdrLeg {
        duration
        from
        hangupReason
        legId
        to
      }
      otherData
      serverTimeStamp
      sessionId
      status
      tags
      to {
        display
        id
        type
      }
      type
      updatedAt
      updatedBy
      workspace
    }
  }
`;
export const numberBuy = /* GraphQL */ `
  mutation NumberBuy(
    $country: String!
    $email: String
    $name: String
    $number: String!
    $provider: String!
    $workspace: String!
  ) {
    numberBuy(
      country: $country
      email: $email
      name: $name
      number: $number
      provider: $provider
      workspace: $workspace
    )
  }
`;
export const publishFlow = /* GraphQL */ `
  mutation PublishFlow($flowId: String!, $version: Int!, $workspace: String!) {
    publishFlow(flowId: $flowId, version: $version, workspace: $workspace) {
      Flow_Start_Node
      Flow_Workspace_Index
      activeVersion
      createdAt
      createdBy
      description
      entry
      id
      isActive
      key
      name
      type
      url
      version
    }
  }
`;
export const reActivateRole = /* GraphQL */ `
  mutation ReActivateRole($role: String!, $workspace: String!) {
    reActivateRole(role: $role, workspace: $workspace) {
      Member_Role_Index
      Member_Status_Role
      Member_Workspace
      invitedAt
      isActive
      isCustomRole
      joinedAt
      joinedMethod
      profile {
        createdAt
        entry
        firstName
        key
        lastName
        poolId
        userId
        userName
      }
      role
      state
      userId
    }
  }
`;
export const readNotification = /* GraphQL */ `
  mutation ReadNotification($notificationId: String!, $workspace: String!) {
    readNotification(notificationId: $notificationId, workspace: $workspace) {
      error
      message
      object
      status
    }
  }
`;
export const removeCreditCard = /* GraphQL */ `
  mutation RemoveCreditCard(
    $cardId: String!
    $provider: String!
    $workspace: String!
  ) {
    removeCreditCard(
      cardId: $cardId
      provider: $provider
      workspace: $workspace
    ) {
      error
      message
      object
      status
    }
  }
`;
export const removeNumberFromTeam = /* GraphQL */ `
  mutation RemoveNumberFromTeam(
    $name: String!
    $number: String!
    $workspace: String!
  ) {
    removeNumberFromTeam(name: $name, number: $number, workspace: $workspace) {
      createdAt
      createdBy
      description
      enabled
      entry
      key
      members {
        Team_Member_MYTeam_Index
        Team_Member_Team_Name
        createdAt
        entry
        key
        profile {
          createdAt
          entry
          firstName
          key
          lastName
          poolId
          userId
          userName
        }
        role
        teamName
        user
      }
      name
      numbers
      setting
      workspace
    }
  }
`;
export const removeUserFromTeam = /* GraphQL */ `
  mutation RemoveUserFromTeam(
    $name: String!
    $user: String!
    $workspace: String!
  ) {
    removeUserFromTeam(name: $name, user: $user, workspace: $workspace) {
      error
      message
      object
      status
    }
  }
`;
export const resetPassword = /* GraphQL */ `
  mutation ResetPassword($user: String!, $workspace: String!) {
    resetPassword(user: $user, workspace: $workspace) {
      error
      message
      object
      status
    }
  }
`;
export const saveFlowNodes = /* GraphQL */ `
  mutation SaveFlowNodes(
    $flowId: String!
    $nodes: [FlowNodeInput]!
    $version: Int!
    $workspace: String!
  ) {
    saveFlowNodes(
      flowId: $flowId
      nodes: $nodes
      version: $version
      workspace: $workspace
    ) {
      error
      message
      object
      status
    }
  }
`;
export const saveNewFlowVersion = /* GraphQL */ `
  mutation SaveNewFlowVersion(
    $firstNode: String
    $flowId: String!
    $name: String!
    $workspace: String!
  ) {
    saveNewFlowVersion(
      firstNode: $firstNode
      flowId: $flowId
      name: $name
      workspace: $workspace
    ) {
      Flow_Start_Node
      createdAt
      createdBy
      entry
      key
      name
      url
      version
    }
  }
`;
export const sendMessage = /* GraphQL */ `
  mutation SendMessage($message: MessageInput!, $workspace: String!) {
    sendMessage(message: $message, workspace: $workspace) {
      conId
      contentType
      from {
        channel
        contact
        display
        id
        type
      }
      interaction {
        channel
        createdAt
        from
        handler
        id
        interactionDetails {
          disposition {
            createdAt
            createdBy
            data
            disposition
            entry
            key
          }
          events {
            createdAt
            entry
            eventCode
            eventData
            eventDisplay
            eventName
            eventType
            id
            key
            sessionId
            timeStamp
          }
          info {
            Interaction_Customer_Index
            Interaction_Handled_By_Index
            Interaction_Status_Index
            Interaction_Time_Index
            Interaction_Type_Time_Index
            Interaction_Workspace_Customer_Index
            Interaction_Workspace_Index
            cdrLegs {
              duration
              from
              hangupReason
              legId
              to
            }
            channel
            createdAt
            customer
            direction
            disposition
            entry
            from {
              display
              id
              type
            }
            handledBy {
              handledAs
              handledAt
              id
            }
            id
            key
            lastHandledBy {
              handledAs
              handledAt
              id
            }
            message
            myCdrLeg {
              duration
              from
              hangupReason
              legId
              to
            }
            otherData
            serverTimeStamp
            sessionId
            status
            tags
            to {
              display
              id
              type
            }
            type
            updatedAt
            updatedBy
            workspace
          }
          notes {
            createdAt
            createdBy
            entry
            id
            key
            message
            noteAttachment {
              contentType
              key
              storage
              type
            }
            updatedAt
          }
        }
        interactionTime
        sessionId
        status
        to
        updatedAt
        workspace
      }
      message
      messageId
      messageType
      receivedAt
      sequence
      to {
        channel
        contact
        display
        id
        type
      }
      type
      workspace
    }
  }
`;
export const sendMessageInternal = /* GraphQL */ `
  mutation SendMessageInternal($message: MessageInput!) {
    sendMessageInternal(message: $message) {
      conId
      contentType
      from {
        channel
        contact
        display
        id
        type
      }
      interaction {
        channel
        createdAt
        from
        handler
        id
        interactionDetails {
          disposition {
            createdAt
            createdBy
            data
            disposition
            entry
            key
          }
          events {
            createdAt
            entry
            eventCode
            eventData
            eventDisplay
            eventName
            eventType
            id
            key
            sessionId
            timeStamp
          }
          info {
            Interaction_Customer_Index
            Interaction_Handled_By_Index
            Interaction_Status_Index
            Interaction_Time_Index
            Interaction_Type_Time_Index
            Interaction_Workspace_Customer_Index
            Interaction_Workspace_Index
            cdrLegs {
              duration
              from
              hangupReason
              legId
              to
            }
            channel
            createdAt
            customer
            direction
            disposition
            entry
            from {
              display
              id
              type
            }
            handledBy {
              handledAs
              handledAt
              id
            }
            id
            key
            lastHandledBy {
              handledAs
              handledAt
              id
            }
            message
            myCdrLeg {
              duration
              from
              hangupReason
              legId
              to
            }
            otherData
            serverTimeStamp
            sessionId
            status
            tags
            to {
              display
              id
              type
            }
            type
            updatedAt
            updatedBy
            workspace
          }
          notes {
            createdAt
            createdBy
            entry
            id
            key
            message
            noteAttachment {
              contentType
              key
              storage
              type
            }
            updatedAt
          }
        }
        interactionTime
        sessionId
        status
        to
        updatedAt
        workspace
      }
      message
      messageId
      messageType
      receivedAt
      sequence
      to {
        channel
        contact
        display
        id
        type
      }
      type
      workspace
    }
  }
`;
export const sendMessageStatus = /* GraphQL */ `
  mutation SendMessageStatus(
    $message: String!
    $receiver: String!
    $sender: String!
    $status: String!
    $thread: String!
  ) {
    sendMessageStatus(
      message: $message
      receiver: $receiver
      sender: $sender
      status: $status
      thread: $thread
    ) {
      message
      receiver
      sender
      status
      thread
    }
  }
`;
export const sendParticipantEvent = /* GraphQL */ `
  mutation SendParticipantEvent(
    $message: MessageSubscriberInput!
    $receiver: String!
  ) {
    sendParticipantEvent(message: $message, receiver: $receiver) {
      conId
      contentType
      from {
        channel
        contact
        display
        id
        type
      }
      message
      messageId
      receivedAt
      receiver
      sequence
      to {
        channel
        contact
        display
        id
        type
      }
      type
      workspace
    }
  }
`;
export const sendSessionEvent = /* GraphQL */ `
  mutation SendSessionEvent(
    $contentType: String
    $message: String
    $receiver: String
    $sessionId: String
    $type: String
    $workspace: String
  ) {
    sendSessionEvent(
      contentType: $contentType
      message: $message
      receiver: $receiver
      sessionId: $sessionId
      type: $type
      workspace: $workspace
    ) {
      contentType
      message
      receiver
      sessionId
      type
      workspace
    }
  }
`;
export const setConsoleStatus = /* GraphQL */ `
  mutation SetConsoleStatus($input: ConsoleStatusInput!) {
    setConsoleStatus(input: $input) {
      entry
      key
      lastRoute
      lastWorkspace
      properLogoff
      status
      statusAt
    }
  }
`;
export const setDefaultCard = /* GraphQL */ `
  mutation SetDefaultCard(
    $cardId: String!
    $provider: String!
    $workspace: String!
  ) {
    setDefaultCard(
      cardId: $cardId
      provider: $provider
      workspace: $workspace
    ) {
      error
      message
      object
      status
    }
  }
`;
export const setMode = /* GraphQL */ `
  mutation SetMode(
    $interactionsessionId: String
    $mode: String
    $reason: String
    $status: String
    $workspace: String!
  ) {
    setMode(
      interactionsessionId: $interactionsessionId
      mode: $mode
      reason: $reason
      status: $status
      workspace: $workspace
    ) {
      error
      message
      object
      status
    }
  }
`;
export const setStatus = /* GraphQL */ `
  mutation SetStatus($state: String!) {
    setStatus(state: $state) {
      error
      message
      object
      status
    }
  }
`;
export const setTrialPackage = /* GraphQL */ `
  mutation SetTrialPackage($provider: String!, $workspace: String!) {
    setTrialPackage(provider: $provider, workspace: $workspace) {
      error
      message
      object
      status
    }
  }
`;
export const subscribeToNumber = /* GraphQL */ `
  mutation SubscribeToNumber(
    $currency: String
    $number: String!
    $provider: String!
    $unitPrice: String
    $workspace: String!
  ) {
    subscribeToNumber(
      currency: $currency
      number: $number
      provider: $provider
      unitPrice: $unitPrice
      workspace: $workspace
    ) {
      error
      message
      object
      status
    }
  }
`;
export const unsubscribePackage = /* GraphQL */ `
  mutation UnsubscribePackage(
    $provider: String!
    $type: String!
    $typeData: String
    $workspace: String!
  ) {
    unsubscribePackage(
      provider: $provider
      type: $type
      typeData: $typeData
      workspace: $workspace
    ) {
      error
      message
      object
      status
    }
  }
`;
export const updateAbandonCallThreshold = /* GraphQL */ `
  mutation UpdateAbandonCallThreshold(
    $entry: String!
    $team: String!
    $threshold: Int
    $timeSlot: String
    $workspace: String!
  ) {
    updateAbandonCallThreshold(
      entry: $entry
      team: $team
      threshold: $threshold
      timeSlot: $timeSlot
      workspace: $workspace
    ) {
      entry
      isActive
      key
      threshold
      timeSlot
    }
  }
`;
export const updateCallRule = /* GraphQL */ `
  mutation UpdateCallRule(
    $number: String!
    $rule: CallRuleInput
    $workspace: String!
  ) {
    updateCallRule(number: $number, rule: $rule, workspace: $workspace) {
      Rule_Number_Index
      createdAt
      createdBy
      description
      direction
      endTimeIndex
      isActive
      name
      number
      offRuleAction
      offRuleActionData
      ruleAction
      ruleActionData
      startTimeIndex
      timeMode
      type
      workspace
    }
  }
`;
export const updateContactDirectoryItem = /* GraphQL */ `
  mutation UpdateContactDirectoryItem(
    $contactDirectory: ContactDirectoryInput!
    $id: String!
    $workspace: String!
  ) {
    updateContactDirectoryItem(
      contactDirectory: $contactDirectory
      id: $id
      workspace: $workspace
    ) {
      Directory_Category_Index
      Directory_Workspace_Index
      Time_Index
      address {
        city
        country
        number
        state
        street
        type
        zipCode
      }
      agent
      contactName
      contactOwnerType
      contactUserType
      createdAt
      createdBy
      email
      fax
      fixedLineNumber
      id
      isEnabled
      mobileNumber
      phoneNumber
      teamName
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $profile: CustomerProfileInput!
    $workspace: String!
  ) {
    updateCustomer(profile: $profile, workspace: $workspace) {
      Customer_User_Index
      Customer_Workspace_Index
      active
      avatar
      birthDay
      contacts {
        Customer_Contact_Index
        contact
        createdAt
        customer
        isPrimary
        isValid
        type
        workspace
      }
      createdAt
      firstName
      gender
      id
      lastInteraction {
        Interaction_Customer_Index
        Interaction_Handled_By_Index
        Interaction_Status_Index
        Interaction_Time_Index
        Interaction_Type_Time_Index
        Interaction_Workspace_Customer_Index
        Interaction_Workspace_Index
        cdrLegs {
          duration
          from
          hangupReason
          legId
          to
        }
        channel
        createdAt
        customer
        direction
        disposition
        entry
        from {
          display
          id
          type
        }
        handledBy {
          handledAs
          handledAt
          id
        }
        id
        key
        lastHandledBy {
          handledAs
          handledAt
          id
        }
        message
        myCdrLeg {
          duration
          from
          hangupReason
          legId
          to
        }
        otherData
        serverTimeStamp
        sessionId
        status
        tags
        to {
          display
          id
          type
        }
        type
        updatedAt
        updatedBy
        workspace
      }
      lastName
      locale
      name
      ssn
      tags
      title
      workspace
    }
  }
`;
export const updateFileName = /* GraphQL */ `
  mutation UpdateFileName(
    $displayName: String
    $key: String
    $type: String
    $version: String
    $workspace: String
  ) {
    updateFileName(
      displayName: $displayName
      key: $key
      type: $type
      version: $version
      workspace: $workspace
    ) {
      contentType
      createdAt
      display
      entry
      file
      key
      owner
      size
      type
      versionId
      workspace
    }
  }
`;
export const updateFlow = /* GraphQL */ `
  mutation UpdateFlow(
    $flow: FlowInput!
    $flowId: String!
    $workspace: String!
  ) {
    updateFlow(flow: $flow, flowId: $flowId, workspace: $workspace) {
      Flow_Start_Node
      Flow_Workspace_Index
      activeVersion
      createdAt
      createdBy
      description
      entry
      id
      isActive
      key
      name
      type
      url
      version
    }
  }
`;
export const updateInteractionHandler = /* GraphQL */ `
  mutation UpdateInteractionHandler(
    $address: InteractionAddressInput!
    $interactionId: String!
    $workspace: String!
  ) {
    updateInteractionHandler(
      address: $address
      interactionId: $interactionId
      workspace: $workspace
    ) {
      Interaction_Customer_Index
      Interaction_Handled_By_Index
      Interaction_Status_Index
      Interaction_Time_Index
      Interaction_Type_Time_Index
      Interaction_Workspace_Customer_Index
      Interaction_Workspace_Index
      cdrLegs {
        duration
        from
        hangupReason
        legId
        to
      }
      channel
      createdAt
      customer
      direction
      disposition
      entry
      from {
        display
        id
        type
      }
      handledBy {
        handledAs
        handledAt
        id
      }
      id
      key
      lastHandledBy {
        handledAs
        handledAt
        id
      }
      message
      myCdrLeg {
        duration
        from
        hangupReason
        legId
        to
      }
      otherData
      serverTimeStamp
      sessionId
      status
      tags
      to {
        display
        id
        type
      }
      type
      updatedAt
      updatedBy
      workspace
    }
  }
`;
export const updateInteractionNote = /* GraphQL */ `
  mutation UpdateInteractionNote(
    $interactionId: String!
    $note: NoteInput
    $noteId: String!
    $workspace: String!
  ) {
    updateInteractionNote(
      interactionId: $interactionId
      note: $note
      noteId: $noteId
      workspace: $workspace
    ) {
      createdAt
      createdBy
      entry
      id
      key
      message
      noteAttachment {
        contentType
        key
        storage
        type
      }
      updatedAt
    }
  }
`;
export const updateMessageStatus = /* GraphQL */ `
  mutation UpdateMessageStatus(
    $interactionDuration: Int
    $interactionStatus: String
    $interactionTime: String
    $messageId: String!
    $senderId: String!
    $status: String
    $threadId: String!
    $workspace: String!
  ) {
    updateMessageStatus(
      interactionDuration: $interactionDuration
      interactionStatus: $interactionStatus
      interactionTime: $interactionTime
      messageId: $messageId
      senderId: $senderId
      status: $status
      threadId: $threadId
      workspace: $workspace
    ) {
      error
      message
      object
      status
    }
  }
`;
export const updateMyProfile = /* GraphQL */ `
  mutation UpdateMyProfile($user: UserInput) {
    updateMyProfile(user: $user) {
      address
      appData
      createdAt
      firstName
      lastName
      metaData
      otherContacts
      phoneNumber
      userName
    }
  }
`;
export const updateStripeCustomer = /* GraphQL */ `
  mutation UpdateStripeCustomer(
    $profile: StripeCustomerInput
    $workspace: String!
  ) {
    updateStripeCustomer(profile: $profile, workspace: $workspace) {
      billingAddress {
        city
        country
        number
        state
        street
        type
        zipCode
      }
      createdAt
      defaultCard
      entry
      firstName
      id
      isActive
      key
      lastName
      lastUpdatedAt
      rechargeAmount
      thresholdAmount
    }
  }
`;
export const updateStripeProductQuantity = /* GraphQL */ `
  mutation UpdateStripeProductQuantity(
    $provider: String!
    $quantity: Int
    $recurringInterval: String
    $type: String!
    $workspace: String!
  ) {
    updateStripeProductQuantity(
      provider: $provider
      quantity: $quantity
      recurringInterval: $recurringInterval
      type: $type
      workspace: $workspace
    ) {
      error
      message
      object
      status
    }
  }
`;
export const updateTag = /* GraphQL */ `
  mutation UpdateTag(
    $description: String
    $isActive: Boolean!
    $name: String!
    $type: String
    $workspace: String!
  ) {
    updateTag(
      description: $description
      isActive: $isActive
      name: $name
      type: $type
      workspace: $workspace
    ) {
      description
      isActive
      name
      type
      workspace
    }
  }
`;
export const updateTeam = /* GraphQL */ `
  mutation UpdateTeam(
    $description: String
    $name: String!
    $setting: String
    $workspace: String!
  ) {
    updateTeam(
      description: $description
      name: $name
      setting: $setting
      workspace: $workspace
    ) {
      createdAt
      createdBy
      description
      enabled
      entry
      key
      members {
        Team_Member_MYTeam_Index
        Team_Member_Team_Name
        createdAt
        entry
        key
        profile {
          createdAt
          entry
          firstName
          key
          lastName
          poolId
          userId
          userName
        }
        role
        teamName
        user
      }
      name
      numbers
      setting
      workspace
    }
  }
`;
export const updateTeamMemberLevel = /* GraphQL */ `
  mutation UpdateTeamMemberLevel(
    $name: String!
    $role: String
    $user: String!
    $workspace: String!
  ) {
    updateTeamMemberLevel(
      name: $name
      role: $role
      user: $user
      workspace: $workspace
    ) {
      error
      message
      object
      status
    }
  }
`;
export const updateWorkspace = /* GraphQL */ `
  mutation UpdateWorkspace($data: WorkspaceInput!, $workspace: String!) {
    updateWorkspace(data: $data, workspace: $workspace) {
      address {
        city
        country
        number
        state
        street
        type
        zipCode
      }
      avatar
      createdAt
      custom_roles
      description
      enabled
      entry
      key
      owner
      ownerId
      setting
      system_roles
      timezone
      updatedAt
      website
    }
  }
`;
