/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { API, graphqlOperation } from "aws-amplify";
import { GetCurrentNumbersQueryVariables, NumberConnector,Number, GetMyTeamsAllQueryVariables, SIPCredentials, Productivity, GetMyProductivityQueryVariables, GetMyProductivityByDateRangeQueryVariables, ProductivityConnector, GetAgentTeamwiseCallCountByDateQueryVariables, CallHistoryCountConnector, CallHistoryCount, GetHourlyAgentProductivityByDateQueryVariables, GetQueueRealtimeItemsByDateQueryVariables, QueueRealtimeConnector, QueueRealtime, UpdateMyProfileMutationVariables, User, MyUserProfile } from "../API";
import { updateMyProfile } from "../graphql/mutations";
import { getAgentTeamwiseCallCountByDate, getCurrentNumbers, getHourlyAgentProductivityByDate, getMyProductivity, getMyProductivityByDateRange, getMyTeamsAll, getMyUserProfile, getQueueRealtimeItemsByDate, getSipCredentials } from "../graphql/queries";

export class  AgentService {    
    
    static getMyNumbers = async (workspace:string): Promise<(Number | null)[] | null | undefined> => {
      try {
        const getCurrentNumbersQueryVariables: GetCurrentNumbersQueryVariables = { workspace ,limit: 100};
        const interaction = await API.graphql({ query: getCurrentNumbers, variables: getCurrentNumbersQueryVariables }) as any;
        const response = interaction.data.getCurrentNumbers as NumberConnector;
        return response?.items;
      } catch (error:any) {
          console.error("AgentService", "getMyNumbers", error);
          return undefined;
      }
    };

    static getMyTeamsAll = async (workspace:string): Promise<Array<string>> => {
      try {
        const getMyTeamsAllQueryVariables: GetMyTeamsAllQueryVariables = { workspace };
        const interaction = await API.graphql({ query: getMyTeamsAll, variables: getMyTeamsAllQueryVariables }) as any;
        const response = JSON.parse(interaction.data.getMyTeamsAll) ;
        return response?.myTeamNames;
      } catch (error:any) {
          console.error("AgentService", "getMyTeamsAll", error);
          return [];
      }
    };

    static getSipCredentials  = async (): Promise<SIPCredentials|undefined> => {
      try {        
        console.warn("AgentService", "getSipCredentials ", "-----------------------------");
        const interaction = await API.graphql({ query: getSipCredentials  }) as any;
        const response = interaction.data.getSipCredentials as SIPCredentials;
        return response;
      } catch (error:any) {
          console.error("AgentService", "getSipCredentials ", error);
          return undefined;
      }
    };

    static getMyProductivity  = async (workspace:string,date:string): Promise<Productivity|undefined> => {
      try {        
        const getMyProductivityQueryVariables: GetMyProductivityQueryVariables = {date ,workspace };
        const interaction = await API.graphql({ query: getMyProductivity , variables: getMyProductivityQueryVariables }) as any;
        const response = interaction.data.getMyProductivity as Productivity;
        return response;
      } catch (error:any) {
          console.error("AgentService", "getMyProductivity ", error);
          return undefined;
      }
    };

    static getMyProductivityByDateRange  = async (workspace:string,startDate:string,endDate:string): Promise<Array<Productivity | null > | null|undefined> => {
      try {        
        const getMyProductivityByDateRangeQueryVariables: GetMyProductivityByDateRangeQueryVariables = {endDate, startDate, workspace, limit:1000};
        const interaction = await API.graphql({ query: getMyProductivityByDateRange , variables: getMyProductivityByDateRangeQueryVariables }) as any;
        const response = interaction.data.getMyProductivityByDateRange as ProductivityConnector;
        return response.items;
      } catch (error:any) {
          console.error("AgentService", "getMyProductivityByDateRange ", error);
          return undefined;
      }
    };

    static getAgentTeamwiseCallCountByDate  = async (workspace:string,agent:string, startDate:string,endDate:string): Promise<Array<CallHistoryCount | null > | null|undefined> => {
      try {        
        const getAgentTeamwiseCallCountByDateQueryVariables: GetAgentTeamwiseCallCountByDateQueryVariables = {agent,endDate,startDate, workspace, limit:1000};
        const interaction = await API.graphql({ query: getAgentTeamwiseCallCountByDate , variables: getAgentTeamwiseCallCountByDateQueryVariables }) as any;
        const response = interaction.data.getAgentTeamwiseCallCountByDate as CallHistoryCountConnector;
        return response.items;
      } catch (error:any) {
          console.error("AgentService", "getAgentTeamwiseCallCountByDate ", error);
          return undefined;
      }
    };
    
    static getHourlyAgentProductivityByDate  = async (workspace:string,agent:string, date:string): Promise<Array<CallHistoryCount | null > | null|undefined> => {
      try {        
        const getHourlyAgentProductivityByDateQuery: GetHourlyAgentProductivityByDateQueryVariables = {agent,date, workspace, limit:1000};
        const interaction = await API.graphql({ query: getHourlyAgentProductivityByDate , variables: getHourlyAgentProductivityByDateQuery }) as any;
        const response = interaction.data.getHourlyAgentProductivityByDate as CallHistoryCountConnector;
        return response.items;
      } catch (error:any) {
          console.error("AgentService", "getHourlyAgentProductivityByDate ", error);
          return undefined;
      }
    };

    static getQueueRealtimeItemsByDate  = async (workspace:string, date:string): Promise<Array<QueueRealtime | null > | null|undefined> => {
      try {        
        const getQueueRealtimeItemsByDateQueryVariables: GetQueueRealtimeItemsByDateQueryVariables = {date, workspace};
        const interaction = await API.graphql({ query: getQueueRealtimeItemsByDate , variables: getQueueRealtimeItemsByDateQueryVariables }) as any;
        const response = interaction.data.getQueueRealtimeItemsByDate as QueueRealtimeConnector;
        return response.items;
      } catch (error:any) {
          console.error("AgentService", "getQueueRealtimeItemsByDate ", error);
          return undefined;
      }
    };

    static updateMyProfile  = async (address:string,firstName: string,lastName: string,userName: string,otherContacts: string,phoneNumber: string,appData:string): Promise<User|undefined> => {
      try {        
        const updateMyProfileMutationVariables: UpdateMyProfileMutationVariables = {user:{address, firstName,lastName,userName,otherContacts,phoneNumber,appData}};
        const interaction = await API.graphql({ query: updateMyProfile , variables: updateMyProfileMutationVariables }) as any;
        const response = interaction.data.updateMyProfile as User;
        return response;
      } catch (error:any) {
          console.error("AgentService", "updateMyProfile ", error);
          return undefined;
      }
    };

    static updateMyProfileAppData  = async (userName: string,appData:string): Promise<User|undefined> => {
      try {        
        const updateMyProfileMutationVariables: UpdateMyProfileMutationVariables = {user:{userName,appData}};
        const interaction = await API.graphql({ query: updateMyProfile , variables: updateMyProfileMutationVariables }) as any;
        const response = interaction.data.updateMyProfile as User;
        return response;
      } catch (error:any) {
          console.error("AgentService", "updateMyProfile ", error);
          return undefined;
      }
    };

    static updateMyProfileData  = async (profile: MyUserProfile): Promise<User|undefined> => {
      try {        
        const updateMyProfileMutationVariables: UpdateMyProfileMutationVariables = {user:{...profile.info}};
        const interaction = await API.graphql({ query: updateMyProfile , variables: updateMyProfileMutationVariables }) as any;
        const response = interaction.data.updateMyProfile as User;
        return response;
      } catch (error:any) {
          console.error("AgentService", "updateMyProfileData ", error);
          return undefined;
      }
    };

    static getAgentProfile  = async (): Promise<MyUserProfile|undefined> => {
      try {        
        const myUserProfileResponse = (await API.graphql(graphqlOperation(getMyUserProfile))) as any;
        const myProfile = myUserProfileResponse.data.getMyUserProfile as MyUserProfile;
        return myProfile;
      } catch (error:any) {
          console.error("AgentService", "getAgentProfile ", error);
          return undefined;
      }
    };
}