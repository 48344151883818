import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AuthState {
  isInitialize:boolean,
}

const initialState: AuthState = {
  isInitialize: false
};

export const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {   
    onInitialized: (state, action: PayloadAction<boolean>) => {
      state.isInitialize = action.payload;
    },
  }
});

export const {  onInitialized } = authSlice.actions;

export default authSlice.reducer;
