import Spin from "antd/lib/spin";
import Result from "antd/lib/result";
import React from 'react';

export interface Props{
    name:string
}
export const Loading = (props:Props) => {
    console.debug(`---------------------------------- Loading[${props.name}]----------------------------------`);   
    return (
        <Result
            icon={<Spin size="large" />}
            title="Welcome to DIALDESK"
        />
    )
}

