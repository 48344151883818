/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WorkspaceTeam } from "../../API";
import { UserService } from "../../services/userService";

export interface phoneState {
  status: string;
  extra?: any;
  audioOutput?: string;
  audioInput?: string;
  online?:boolean;
  isLogOutProcessCompleted?:boolean
}

interface phoneStatus {
  phoneState: phoneState;
  acwSettings: { [key: string]: number };
  acwTime:number
}
const initialState: phoneStatus = {
  phoneState: {
    status: "offline",
    audioOutput: "default",
    audioInput: "default",
    online: false,
    isLogOutProcessCompleted:false,    
  },
  acwSettings:{},
  acwTime:parseInt(process.env.REACT_APP_PHONE_ACW || '10')
};

export const getTeams = createAsyncThunk<any, any>(
  "phoneControllerSlice/getTeams",
  async (workspace: string) => {
    try {
      return await UserService.getTeams(workspace);
    } catch (error) {
      console.error("error occurred when loading teams", error);
      return undefined;
    }
  }
);

export const phoneControllerSlice = createSlice({
  name: "callController",
  initialState,
  reducers: {
    onPhoneOffline: (state, action: PayloadAction<phoneState>) => {
      state.phoneState = { ...action.payload , ...{online:false }};
    },
    onPhoneInitializing: (state, action: PayloadAction<phoneState>) => {
      state.phoneState = { ...action.payload , ...{online:false }};
    },
    onPhoneConnecting: (state, action: PayloadAction<phoneState>) => {
      state.phoneState = { ...action.payload , ...{online:false }};
    },
    onPhoneConnected: (state, action: PayloadAction<phoneState>) => {
      state.phoneState = { ...action.payload , ...{online:false }};
    },
    onPhoneDisconnected: (state, action: PayloadAction<phoneState>) => {
      state.phoneState = { ...action.payload , ...{online:false }};
    },
    onPhoneRegistered: (state, action: PayloadAction<phoneState>) => {
      state.phoneState = { ...action.payload , ...{online:true }};
    },
    onPhoneUnregistered: (state, action: PayloadAction<phoneState>) => {
      state.phoneState = { ...action.payload , ...{online:false }};
    },
    onPhoneRegistrationFailed: (state, action: PayloadAction<phoneState>) => {
      state.phoneState = { ...action.payload , ...{online:false }};
    },
    onPhoneRegistrationExpiring: (state, action: PayloadAction<phoneState>) => {
      //state.phoneState = { ...action.payload , ...{online:false }};
      state.phoneState = { ...action.payload , ...{online:true }}; // todo => revert this after fixed websockt disconnecting issue
    },
    onAudioOutputChange: (state, action: PayloadAction<phoneState>) => {
      state.phoneState.audioOutput = action.payload.audioOutput;
    },
    onAudioInputChange: (state, action: PayloadAction<phoneState>) => {
      state.phoneState.audioInput = action.payload.audioInput;
    },
    onSetAcwTime: (state, action: PayloadAction<{team:string}>) => {
      state.acwTime = state.acwSettings[action.payload.team]??10;
    },
  },
  extraReducers:(builder) => {
    builder.addCase(getTeams.fulfilled, (state, action:PayloadAction<WorkspaceTeam[]|undefined>) => {
      const settings:{ [key: string]: number } ={};
      settings["default"] = parseInt(process.env.REACT_APP_PHONE_ACW || '10');
      if (action.payload) {
          action.payload.forEach((item)=>{
            try {
              const data = JSON.parse(item.setting??"");
              if(data){
                settings[item.name] = data.acwTime??settings["default"];
              }
            // eslint-disable-next-line no-empty
            } catch (error) {
              
            }
          })

      }
      state.acwSettings = settings;
    })
  }
});

export const {
  onAudioInputChange,
  onAudioOutputChange,
  onPhoneOffline,
  onPhoneInitializing,
  onPhoneConnecting,
  onPhoneConnected,
  onPhoneDisconnected,
  onPhoneRegistered,
  onPhoneUnregistered,
  onPhoneRegistrationFailed,
  onPhoneRegistrationExpiring,
  onSetAcwTime
} = phoneControllerSlice.actions;

export default phoneControllerSlice.reducer;
