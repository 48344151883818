/* eslint-disable @typescript-eslint/no-explicit-any */
import { API } from "aws-amplify";
import { AddRecentDialMutationVariables, GetRecentDialQueryVariables, RecentDial, RecentDialConnector, RecentDialInput } from "../API";
import { addRecentDial } from "../graphql/mutations";
import { getRecentDial } from "../graphql/queries";

export default  new(class ActivityService {
    
    addRecentDial = async (recentDial:RecentDialInput,workspace:string) => {
        try {
            const addRecentDialMutationVariables: AddRecentDialMutationVariables = {
              recentDial,
              workspace,
            }
            const result = await API.graphql({ query: addRecentDial, variables: addRecentDialMutationVariables }) as any;
            const response = result.data.addRecentDial ;
            return response.object as RecentDial;
        }
        catch (error:any) {
            console.error("ActivityService", "addCallTag", error);
            return null
        }
    }

    getRecentDial = async (workspace:string,nextToken?: string | null | undefined): Promise<RecentDialConnector> => {
        try {
          const getRecentDialQueryVariables: GetRecentDialQueryVariables = { workspace ,nextToken ,limit: 100 };
          const interaction = await API.graphql({ query: getRecentDial, variables: getRecentDialQueryVariables }) as any;
          const response = interaction.data.getRecentDial as RecentDialConnector;
          return response;
        } catch (error:any) {
            console.error("UserService", "getRecentDial", error);
            return error.data.getRecentDial;
        }
    }

})();