/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { API } from "aws-amplify";
import { GetMessagesQueryVariables,  Message,  MessageAddressInput,  MessageConnector, MessageInteractionInput, SendMessageMutationVariables, SendMessageStatusMutationVariables, UpdateMessageStatusMutationVariables} from "../API";
import { MessageStatus } from "../constants/enum";
import { TempChatMessage } from "../constants/interfaces";
import { sendMessage, sendMessageStatus, updateMessageStatus } from "../graphql/mutations";
import { getMessages } from "../graphql/queries";

export class MessageService {
   
    private static sequence = 1;    
    
    static getMessages = async(convId: string ,  nextToken: string | null | undefined,workspace: string):Promise<MessageConnector|undefined> =>{
        try {
            const getMessagesQueryVariables: GetMessagesQueryVariables = {
                convId ,
                workspace,
                limit : 100,
                nextToken
            }; 
            const interaction = await API.graphql({ query: getMessages, variables: getMessagesQueryVariables }) as any;
            return interaction.data.getMessages as MessageConnector
        } catch (error:any) {
            console.error("MessageService", "getInteractionById", error);
            return error.data?.getMessages as MessageConnector
        }
    }

    static sendMessage = async(message: string,workspace: string,from :MessageAddressInput,to:MessageAddressInput,type:string, interaction:MessageInteractionInput|undefined):Promise<Message|undefined> =>{
        try {
            const sendMessageMutationVariables: SendMessageMutationVariables = {
                message: {
                    contentType: "text",
                    from ,
                    interaction,
                    message,
                    messageType: "text",
                    sequence: this.sequence++,
                    to,
                    type,
                    workspace
                },
                workspace
            };
            const response = await API.graphql({ query: sendMessage, variables: sendMessageMutationVariables }) as any;
            return response.data.sendMessage as Message
        } catch (error) {
            console.error("MessageService", "sendMessage", error);
            return undefined;
        }
    }
    static sendChatMessage = async(message: TempChatMessage,workspace: string,interaction:MessageInteractionInput|undefined):Promise<Message|undefined> =>{
        try {
            const to : MessageAddressInput = { id:message.to.id, type:message.to.type };
            const from : MessageAddressInput = { id: message.from.id,  type: message.from.type }
            const sendMessageMutationVariables: SendMessageMutationVariables = {
                message: {
                    contentType: "text",
                    from ,
                    interaction,
                    message : message.message,
                    messageType: message.messageType,
                    sequence: this.sequence++,
                    to ,
                    type : message.chatType,
                    workspace,
                },
                workspace
            };
            const response = await API.graphql({ query: sendMessage, variables: sendMessageMutationVariables }) as any;
            return response.data.sendMessage as Message
        } catch (error) {
            console.error("MessageService", "sendMessage", error);
            return undefined;
        }
    }
    static updateMessageStatus = async(message: Message, status:MessageStatus):Promise<Message|undefined> =>{
        try {
            const updateMessageStatusMutationVariables: UpdateMessageStatusMutationVariables = {
                messageId: message.messageId ?? "Not SET",
                senderId: message.from.id,
                threadId: message.conId ?? "Not SET",
                workspace: message.workspace,
                status
            };
            const response = await API.graphql({ query: updateMessageStatus, variables: updateMessageStatusMutationVariables }) as any;
            return response.data.updateMessageStatus as Message
        } catch (error) {
            console.error("MessageService", "sendMessageStatus", error);
            return undefined;
        }
    }
}