import Auth from "@aws-amplify/auth";

export const authService = {
    isAdminRole,
    isAuthenticated,
    refreshUserSession,
    getValidRoutePath,
    getUser,
    signOut
}


function isAdminRole(roleName: string) {
    return roleName?.toLowerCase() === "admin" || roleName?.toLocaleLowerCase() === "supervisor";// (roleName !== null && roleName !== 'agent');
}

async function isAuthenticated() {
    //let token = (await Auth.currentSession()).getIdToken().getJwtToken();
    try {
        const user = await Auth.currentAuthenticatedUser();
        return user != null;
    } catch (e) {
        return false;
    }
}

async function getUser(){
    const user = await Auth.currentAuthenticatedUser();
    return user;
}

async function signOut(){
    await Auth.signOut();
}

// Refresh the user session forcefully
async function refreshUserSession() {
    await Auth.currentAuthenticatedUser({ bypassCache: true });
}

function getValidRoutePath(path: string) {
    try {
        console.log("auth service", "isValidRoutePath", `path : ${path}`);
        const paths: string[] = ["/console/workspace", "/console/invitation", "/console/dashboard/overview", "/console/dashboard/counter", "/console/dashboard/countercl"];
        const found = paths.find(element => element === path);
        return (found === undefined || found === "") ? "/console" : found;
    } catch (error) {
        console.error("auth service", "isValidRoutePath", error);
        return "/console";
    }
}