/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { API } from "aws-amplify";
import { GetUploadUrlMutationVariables } from "../API";
import { getUploadUrl } from "../graphql/mutations";
import { aws_config } from "../config/awsConfig";

export class FileService {

  static getUploadUrl = async (action: string, contentType: string, display: string, owner: string, type: string, workspace: string, key?: string): Promise<string> => {
    try {
      const getUploadUrlMutationVariables: GetUploadUrlMutationVariables = { action, contentType, display, owner, type, workspace, key };
      const interaction = await API.graphql({ query: getUploadUrl, variables: getUploadUrlMutationVariables }) as any;
      return interaction.data.getUploadUrl;
    } catch (error) {
      console.error("FileService", "getUploadUrl", error);
      return "undefined";
    }
  }


  static uploadAvatar = async (filename: string, owner: string, file: any, contentType: string, workspace: string): Promise<string | undefined> => {
    try {
      const response = await this.getUploadUrl('put', contentType, filename, owner, aws_config.bucket.avatar_bucket_name, workspace);
      const parsedResponse = JSON.parse(response);
      if (parsedResponse && parsedResponse.data && parsedResponse.data.url) {
        const requestOptions = {
          method: 'PUT',
          headers: { 'Content-Type': contentType },
          body: file
        };
        const response = await fetch(parsedResponse.data.url, requestOptions);
        if (response.status === 200) {
          return parsedResponse.displayUrl;
        }
        return undefined;
      } else {
        return undefined;
      }

    } catch (error) {
      console.error("FileService", "getUploadUrl", error);
      return undefined;
    }
  }

  static getRecordingKey = (recordingKey: string | undefined | null) => {
    if (recordingKey) {
      const splitted = recordingKey.split("callRecordings/")[1];
      console.log("splitted", splitted);
      return splitted;
    }
    else {
      return undefined
    }
  }
}