/* eslint-disable @typescript-eslint/no-explicit-any */
import { API } from "aws-amplify";
import { ConsoleStatusInput, SetModeMutationVariables } from "../../../API";
import { setConsoleStatus, setMode } from "../../../graphql/mutations";
import { RoleItems } from "../../../models/role";
import { WorkspaceContextDetails } from "../../../models/workspace";
import { authService } from "../../account/services/authService";

export const workspaceService = {
    getWorkspaceContextsFromRoles,
    setAgentConsoleStatus,
    setUserMode
}

function getWorkspaceContextsFromRoles(currentRoleWS: RoleItems, otherRoleWS: any) {
    const currentWorkSpaceContext: WorkspaceContextDetails = {
        isAdmin: authService.isAdminRole(currentRoleWS?.role || ''), name: currentRoleWS?.Member_Workspace || ''
    };
    const otherWorkSpaceContexts: WorkspaceContextDetails[] = otherRoleWS?.map((otherWorkspace:any) => {
        return {
            isAdmin: authService.isAdminRole(currentRoleWS?.role || ''), name: otherWorkspace?.Member_Workspace || ''
        }
    });
    return { currentWorkSpaceContext, otherWorkSpaceContexts };
}

async function setAgentConsoleStatus(currentWS: string, lastRoute?: string) {
    try {
        const consoleStatusInput: ConsoleStatusInput = {
            lastWorkspace: currentWS,
            lastRoute: lastRoute ?? window.location.hash.split('#')[1]
        };
        return await API.graphql({ query: setConsoleStatus, variables: { input: consoleStatusInput } });
    } catch (error) {
        console.error("WorkspaceService setAgentConsoleStatus",error);
    }    
}

async function setUserMode(setStatusVarible: SetModeMutationVariables) {
    console.log("WorkspaceService SetUserMode", setStatusVarible);
    try {
        await API.graphql({ query: setMode, variables: setStatusVarible });
    }
    catch (error) {
        console.error("WorkspaceService SetUserMode",error);
        throw error;
    }
}