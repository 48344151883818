/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
export enum AgentStatus {
  online = "Online",
  donotdisturb = "Do not Disturb",
  break = "Break",
  offline = "Offline",
  acw="ACW",
  busy="InCall"
}

enum Colors {
  RED = <any>"RED COLOR",
  BLUE = <any>"BLUE COLOR",
  GREEN = <any>"GREEN COLOR"
}

export enum AgentMode {
  inbound = "Inbound",
  outbound = "Outbound",
}

export enum InteractionStatus {
  ongoing = "Ongoing",
  past = "Past",
}

export enum InteractionTypes {
  client = "client",
  agent = "agent",
}

export enum CallDirection {
  inbound = 0,
  outbound = 1,
  none = 3,
}

export enum MediaType {
  audio = 0,
  video = 1,
}

export enum CallType {
  Inbound = "Inbound",
  Outbound = "Outbound",
  Transfer = "Transfer",
}

export enum CallOperationType {
  DEFAULT = "DEFAULT",
  HOLD = "HOLD",
  TRANSFER = "TRANSFER",
  MUTE = "MUTE",
  BARGEIN = "BARGEIN",
  ATTENDEDTRANSFER = "ATTENDED TRANSFER",
}

export enum CallOperationStatus {
  Incoming = "Incoming",
  Outgoing = "Outgoing",
  Miscall = "Miscall",
}

export enum ContactTypes {
  Agent = "Agent",
  Customer = "Customer",
  Team = "Team",
  Direct ="Direct",
  Unknown="Unknown"
}

export enum ChatTypes {
  Customer = "customer",
  Team = "team",
  Direct ="direct",
  Unknown="unknown"
}

export enum MessageStatus {
  seen = "seen",
  delivered = "delivered",
  received ="received",
  send ="send"
}

export enum ProfileLoadAction {
  pastConversations,
  ongoingConversations,
  ongoingInteractions,
  interactionBox,
  unMapInteractions
}