/* eslint-disable @typescript-eslint/no-explicit-any */
import { API } from "aws-amplify";
import { GetCustomersDetailByContactQueryVariables, CustomerContact, GetCustomerDetailsByIdQueryVariables, CustomerDetail, CreateCustomerMutationVariables, Customer, CustomerProfileInput, AddCustomerContactMutationVariables, CustomerContactsInput, AddCustomerTagMutationVariables, ChangeCustomerAddressMutationVariables, Address, AddressInput, SearchQueryVariables, Search, GetCustomersQueryVariables, CustomerConnector, UpdateCustomerMutationVariables, DeleteCustomerTagMutationVariables, DeleteCustomerContactMutationVariables } from "../API";
import { addCustomerContact, addCustomerTag, changeCustomerAddress, createCustomer, deleteCustomerContact, deleteCustomerTag, updateCustomer } from "../graphql/mutations";
import { getCustomerDetailsById, getCustomers, getCustomersDetailByContact, search } from "../graphql/queries";
import { WorkspaceContextDetails } from "../models/workspace";
import { CustomerSearchDetails } from "../redux/slice/customerDetailsSlice";

export class CustomerService {

    

    static getCustomers = async (workspace: string): Promise<(Customer | null)[] | null | undefined> => {
        try {
            const getCustomersQueryVariables: GetCustomersQueryVariables = {
                workspace,limit:100000
            };
            const interaction = await API.graphql({ query: getCustomers, variables: getCustomersQueryVariables }) as any;
            const data = interaction.data.getCustomers as CustomerConnector;
            return data.items;
        } catch (error) {
            console.error("customerService", "getCustomers", error);
            return undefined;
        }
    }

    static getCustomersDetailByContact = async (workspace: string, contact: string): Promise<CustomerContact[] | undefined> => {
        try {
            const getCustomersDetailByContactQueryVariables: GetCustomersDetailByContactQueryVariables = {
                contact,
                workspace
            };
            const interaction = await API.graphql({ query: getCustomersDetailByContact, variables: getCustomersDetailByContactQueryVariables }) as any;
            return interaction.data.getCustomersDetailByContact as CustomerContact[]
        } catch (error) {
            console.error("customerService", "getCustomersDetailByContact", error);
            return undefined;
        }
    }

    static getCustomerDetails = async (id: string, workspace: string) => {
        try {
            //let workspace = store.getState().workspace;
            const temp =  id.split("_");
            const getCustomerDetailsByIdQueryVariables: GetCustomerDetailsByIdQueryVariables = {
                workspace: workspace,
                id: Array.isArray(temp)&& temp.length>1 ? temp[1] : id
            }
            const result = await API.graphql({ query: getCustomerDetailsById, variables: getCustomerDetailsByIdQueryVariables }) as any;
            const customer = result.data.getCustomerDetailsById as CustomerDetail;
            //store.dispatch(setCustomerDetails(customer));
            return customer;
        }
        catch (error) {
            console.error("customerService", "getCustomerDetails", error);
            return undefined;
        }
    }

    static getCustomerProfile = async (id: string, workspace: string,key: string,tempKey?:string): Promise<CustomerDetail&{key:string,tempKey?:string}|undefined> => {
        try {           
            const getCustomerDetailsByIdQueryVariables: GetCustomerDetailsByIdQueryVariables = {
                workspace: workspace,
                id
            }
            const result = await API.graphql({ query: getCustomerDetailsById, variables: getCustomerDetailsByIdQueryVariables }) as any;
            const customer = result.data.getCustomerDetailsById as CustomerDetail;
            return {tempKey,key, ...customer};
        }
        catch (error) {
            console.error("customerService", "getCustomerDetails", error);
            return undefined;
        }
    }

    static searchCustomers = async (customerSearchDetails: CustomerSearchDetails) => {
        const searchQueryVariable: SearchQueryVariables = {
            content: customerSearchDetails.searchString,
            workspace: customerSearchDetails.workspace,
            field: customerSearchDetails.searchType
        };
        const result = await API.graphql({ query: search, variables: searchQueryVariable }) as any;
        const searchResults = result.data.search as Search[];
        const filteredCustomers = (searchResults.map(s => {
            if (s.type === "customer" && s.source) {
                const customer = JSON.parse(s.source) as Customer;
                return customer;
            }
        })).filter(sr => sr !== undefined) as Customer[];
        return filteredCustomers;
    }

    static createCustomer = async (profile: CustomerProfileInput, workspace: string) => {
        try {
            const createCustomerMutationVariables: CreateCustomerMutationVariables = {
                profile,
                workspace: workspace
            }
            const result = await API.graphql({ query: createCustomer, variables: createCustomerMutationVariables }) as any;
            const customer = result.data.createCustomer as Customer;
            return customer;
        }
        catch (error: any) {
            console.error("customerService", "createCustomer", error);
            return error.data.createCustomer as Customer
        }
    }

    static updateCustomer = async (profile: CustomerProfileInput, workspace: string) => {
        try {
            const updateCustomerMutationVariables: UpdateCustomerMutationVariables = {
                profile,
                workspace: workspace
            }
            const result = await API.graphql({ query: updateCustomer, variables: updateCustomerMutationVariables }) as any;
            const customer = result.data.updateCustomer as Customer;
            return customer;
        }
        catch (error: any) {
            console.error("customerService", "updateCustomer", error);
            return error.data.updateCustomer as Customer
        }
    }

    static addCustomerContact = async (contact: CustomerContactsInput, user: string, workspace: WorkspaceContextDetails) => {
        try {
            //let workspace = store.getState().workspace;
            const addCustomerContactMutationVariables: AddCustomerContactMutationVariables = {
                contact,
                user,
                workspace: workspace.name
            }
            const result = await API.graphql({ query: addCustomerContact, variables: addCustomerContactMutationVariables }) as any;
            const customer = result.data.addCustomerContact as CustomerContact;
            return customer;
        }
        catch (error: any) {
            console.error("customerService", "addCustomerContact", error);
            return error.data.createCustomer as CustomerContact
        }
    }

    static removeCustomerContact = async (contactValue: string, user: string, workspace: WorkspaceContextDetails) => {
        try {
            const deleteCustomerContactMutationVariables: DeleteCustomerContactMutationVariables = {
                contact: contactValue,
                user,
                workspace: workspace.name
            }
            const result = await API.graphql({ query: deleteCustomerContact, variables: deleteCustomerContactMutationVariables }) as any;
            const customer = result.data.deleteCustomerContact as CustomerContact;
            return customer;
        }
        catch (error: any) {
            console.error("customerService", "deleteCustomerContact", error);
            return error.data.deleteCustomerContact as CustomerContact
        }
    }

    static addCustomerTag = async (tag: string, user: string, workspace: WorkspaceContextDetails) => {
        try {
            //let workspace = store.getState().workspace;
            const addCustomerTagMutationVariables: AddCustomerTagMutationVariables = {
                user,
                workspace: workspace.name,
                tag
            }
            const result = await API.graphql({ query: addCustomerTag, variables: addCustomerTagMutationVariables }) as any;
            const customer = result.data.addCustomerTag as Customer;
            return customer;
        }
        catch (error: any) {
            console.error("customerService", "addCustomerTag", error);
            return error.data.addCustomerTag as Customer
        }
    }

    static removeCustomerTag = async (tag: string, user: string, workspace: WorkspaceContextDetails) => {
        try {
            const deleteCustomerTagMutationVariables: DeleteCustomerTagMutationVariables = {
                user,
                workspace: workspace.name,
                tag
            }
            const result = await API.graphql({ query: deleteCustomerTag, variables: deleteCustomerTagMutationVariables }) as any;
            const customer = result.data.deleteCustomerTag as Customer;
            return customer;
        }
        catch (error: any) {
            console.error("customerService", "removeCustomerTag", error);
            return error.data.deleteCustomerTag as Customer
        }
    }

    static changeCustomerAddress = async (address: AddressInput, user: string, workspace: WorkspaceContextDetails) => {
        try {
            //let workspace = store.getState().workspace;
            const changeCustomerAddressMutationVariables: ChangeCustomerAddressMutationVariables = {
                address,
                user,
                workspace: workspace.name,
            }
            const result = await API.graphql({ query: changeCustomerAddress, variables: changeCustomerAddressMutationVariables }) as any;
            const customer = result.data.changeCustomerAddress as Address;
            return customer;
        }
        catch (error: any) {
            console.error("customerService", "changeCustomerAddress", error);
            return error.data.changeCustomerAddress as Address
        }
    }


}