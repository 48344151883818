import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SubscribeMessageStatusSubscriptionVariables } from '../../API'

export interface messageStatusState {
  messageStatus:SubscribeMessageStatusSubscriptionVariables
}

const initialState: messageStatusState = {
  messageStatus: {
    receiver: ''
  }
}

export const messageSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {    
    OnMessageStatusReceived: (state, action: PayloadAction<SubscribeMessageStatusSubscriptionVariables>) => {
      state.messageStatus = {...action.payload}      
    }
  },
})

export const { OnMessageStatusReceived} = messageSlice.actions

export default messageSlice.reducer