/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import message from 'antd/lib/message';
import moment from 'moment';
import { ConsoleStatusInput, SetModeMutationVariables, UserMode,Invitation, MyUserProfile, WorkspaceMember, Workspace } from '../../API';
import { aws_config } from '../../config/awsConfig';
import { consoleLargeTextBlack } from '../../constants/constraints';
import { AgentMode, AgentStatus } from '../../constants/enum';
import { InitialConsoleStatusDetails, WorkspaceContextDetails } from '../../models/workspace'
import { authService } from '../../modules/account/services/authService';
import { userService } from '../../modules/workspace/services/userService';
import { workspaceService } from '../../modules/workspace/services/workspaceService';
import { WorkspaceService } from '../../services/workspaceService';
import { fetchMyTeamsAll } from './agentSlice';

export interface WorkspaceContextState {
    currentWorkspace: WorkspaceContextDetails,
    otherWorkspaces: WorkspaceContextDetails[],
    pendingInvitations: Invitation[],
    currentUserMode: UserMode,
    userModes: UserMode[],
    profile?:MyUserProfile
}

const initialState: WorkspaceContextState = {
    currentWorkspace: { isAdmin: false, name: '' },
    otherWorkspaces: [],
    pendingInvitations: [],
    currentUserMode: { __typename: 'UserMode', workspace: '', entry: '', key: '', mode: AgentMode.outbound.toString(),status:AgentStatus.offline.toString() },
    userModes: [],
}

export const setAgentConsoleStatusAsync = createAsyncThunk(
    'workspaceContext/setAgentConsoleStatus',
    async (currentWS: string) => {
        console.log("Update Console Status on WS Switching");
        try {
            await workspaceService.setAgentConsoleStatus(currentWS);
        }
        catch (error) {
            console.error("workspaceContextSlice", "setAgentConsoleStatus", error);
        }
    }
);

export const setAgentConsoleStatusOnRouteChangeAsync = createAsyncThunk(
    'workspaceContext/setAgentConsoleStatusOnRouteChange',
    async (consoleStatusInput: ConsoleStatusInput) => {
        try {
            console.log("workspaceSlice", "setAgentConsoleStatusOnRouteChange", consoleStatusInput);
            await workspaceService.setAgentConsoleStatus(consoleStatusInput.lastWorkspace ?? '', consoleStatusInput.lastRoute ?? '');
        }
        catch (error) {
            console.error("workspaceContextSice", "setAgentConsoleStatusOnRouteChange", error);
        }
    }
);

export const setUserModeAsync = createAsyncThunk(
    'workspaceContext/setUserModeAsync',
    async (setStatusVariable: SetModeMutationVariables&{updateBy?:string},{getState}) => {
        console.debug("workspaceSlice", "setUserModeAsync", setStatusVariable);
        //let currentStatus  = AgentStatus[statusKey as keyof typeof AgentStatus];
        
        /* if(setStatusVariable.status){
            const aa = Object.entries(AgentStatus).find(([key,value]) => (key === setStatusVariable.status || value=== setStatusVariable.status));
            if(aa){
                setStatusVariable.status = aa[0];
            }
            let indexOfApple = Object.keys(AgentStatus).indexOf(setStatusVariable.status as unknown as AgentStatus);
            if(indexOfApple < 0){
                indexOfApple = Object.values(AgentStatus).indexOf(setStatusVariable.status as unknown as AgentStatus);
            }
            setStatusVariable.status = Object.keys(AgentStatus)[indexOfApple]
        } */
        
        if(setStatusVariable.status){
            const status = Object.entries(AgentStatus).find(([key,value]) => (key === setStatusVariable.status || value=== setStatusVariable.status));
            if(status){
                setStatusVariable.status = status[0];
            }           
        }
        
        if(setStatusVariable.mode){
            const mode = Object.entries(AgentMode).find(([key,value]) => (key === setStatusVariable.mode || value=== setStatusVariable.mode));
            if(mode){
                setStatusVariable.mode = mode[0];
            }
        }

        const workspaceState = (getState() as any)?.workspace as WorkspaceContextState;
        
        if(!setStatusVariable.workspace){
            console.log("workspaceSlice", "setUserModeAsync", "workspace empty, set current workspace");
            setStatusVariable.workspace = workspaceState.currentWorkspace.name;
        }
        if(workspaceState.currentUserMode.status !== setStatusVariable.status){
            console.log('%c Agent status updated - THUNK! [workspace : %s , state : %s , mode : %s , statusAction : %s , updateBy : %s', consoleLargeTextBlack,setStatusVariable.workspace,setStatusVariable.status,setStatusVariable.mode,setStatusVariable.reason,setStatusVariable.updateBy??"APP" );            
            await workspaceService.setUserMode(setStatusVariable);
            return setStatusVariable;
        }else{
            console.log("workspaceSlice", "setUserModeAsync", "ignoring update due to on same state");
            return undefined;
        }       
    }
);

export const fetchWorkspaceDetails = createAsyncThunk(
    'workspaceContext/fetchWorkspaceDetails',
    async (workspaces: WorkspaceContextDetails[]) => {
        try {
            console.log("workspaceSlice", "fetchWorkspaceDetails", workspaces);
            const func_array:any[] = [];
            workspaces.forEach((w)=>{
                func_array.push( WorkspaceService.getWorkspace(w.name));
            })
            
            return await Promise.all<(Workspace | undefined)[]>(func_array);
        }
        catch (error) {
            console.error("workspaceContextSice", "fetchWorkspaceDetails", error);
            return undefined;
        }
    }
);

export const initializeConsoleStatusAsync = createAsyncThunk(
    'workspaceContext/initializeConsoleStatusAsync',
    async (forceRefreshProfile: boolean,{dispatch}) => {
        console.log("Getting User......");

        const user = await authService.getUser();

        const initialConsoleStatusDetails: InitialConsoleStatusDetails = {
            currentWorkspace: { name: '', isAdmin: false },
            otherWorkspaces: [],
            pendingInvitations: [],
            userModes: [],
            redirectRoute: null,
            user: null
        };

        if (user != null) {
            initialConsoleStatusDetails.user = user;
            const profile = await userService.getMyProfile(forceRefreshProfile);
            console.log("profile",profile);
            let workspaceDetails;
            if (profile) {
                const invitations = profile.invitations;
                const consoleStatus = profile.consoleStatus;
                const roleDetails = profile.roles;
                initialConsoleStatusDetails.profile = profile;
                

                initialConsoleStatusDetails.redirectRoute = consoleStatus?.lastRoute ?? '/console';
                const otherRoleWS: WorkspaceMember[]|undefined = roleDetails?.filter((r)=> r?.state?.toLowerCase() === "joined").filter((item): item is WorkspaceMember => !!item);
                let currentRoleWS: any = {};
                if (consoleStatus?.lastWorkspace) { 
                    currentRoleWS = otherRoleWS?.find((r)=> r.Member_Workspace === consoleStatus?.lastWorkspace);                            
                                               
                }else if(otherRoleWS && otherRoleWS.length > 0){
                    currentRoleWS = otherRoleWS[0];
                }
                await workspaceService.setAgentConsoleStatus(currentRoleWS?.Member_Workspace ?? ''); 
                workspaceDetails = workspaceService.getWorkspaceContextsFromRoles(currentRoleWS, otherRoleWS);
                if (invitations && invitations.length > 0) {
                    const pendingInvitations = invitations.filter((i) => i?.status === "pending");
                    if (pendingInvitations.length > 0) { // No accepted invitations then go to accept any
                        console.log("Pending invitation"); //data?.items?.filter((item): item is ContactDirectoryItem => !!item)
                        initialConsoleStatusDetails.pendingInvitations = pendingInvitations?.filter((item): item is Invitation => !!item)
                        initialConsoleStatusDetails.redirectRoute = "/console/invitation";
                        workspaceDetails = workspaceService.getWorkspaceContextsFromRoles(currentRoleWS, otherRoleWS);
                    }
                    else {
                       
                        initialConsoleStatusDetails.redirectRoute =  consoleStatus?.lastRoute ?? '/console';
                        workspaceDetails = workspaceService.getWorkspaceContextsFromRoles(currentRoleWS ?? {}, otherRoleWS);
                    }
                } 
                else if(roleDetails?.length === 0) { // No workspace
                    console.log("No workspace.. Create one");
                    workspaceDetails = workspaceService.getWorkspaceContextsFromRoles({}, []);
                    initialConsoleStatusDetails.redirectRoute = '/console/workspace';
                }
                initialConsoleStatusDetails.currentWorkspace = workspaceDetails.currentWorkSpaceContext;
                initialConsoleStatusDetails.otherWorkspaces = workspaceDetails.otherWorkSpaceContexts;

                if (profile.mode) {
                    initialConsoleStatusDetails.userModes = profile.mode?.filter((item): item is UserMode => !!item) // Set user modes
                    const currentMode = initialConsoleStatusDetails.userModes.find((mode) => mode.workspace === initialConsoleStatusDetails.currentWorkspace.name);
                    console.log("CurrentMode", currentMode);

                    if (currentMode && currentMode.status && currentMode.reason !== 'logoff' && currentMode.updatedAt) {
                        const momentNow = moment(new Date().toUTCString());
                        const lastUpdatedMoment = moment(currentMode.updatedAt);
                        const differenceInMinutes = moment.duration(momentNow.diff(lastUpdatedMoment)).asMinutes();
                        const continueModeThreshold = parseInt(process.env.REACT_APP_CONTINUE_MODE_TIME_THRESHOLD_MINUTES || '60');
                        if (differenceInMinutes >= continueModeThreshold) {
                            const continueStatusVarible: SetModeMutationVariables = {
                                workspace: currentMode.workspace,
                                reason: "continue"
                            };
                            await workspaceService.setUserMode(continueStatusVarible);
                        }
                    }
                }
            }
            else {
                console.error("workspaceService", "initializeConsoleStatusAsync", "No profile to set status");
                await authService.signOut();
            }
        }
        else {
            console.error("workspaceService", "initializeConsoleStatusAsync", "Not logged in");
            initialConsoleStatusDetails.redirectRoute = '/403';
            await authService.signOut();
        }
        console.log("Returning initial status");

        console.log(initialConsoleStatusDetails);
        dispatch(fetchMyTeamsAll(initialConsoleStatusDetails.currentWorkspace.name));
        dispatch(fetchWorkspaceDetails(initialConsoleStatusDetails.otherWorkspaces));
        return initialConsoleStatusDetails;
    }
);

export const workspaceSlice = createSlice({
    name: 'workspaceContext',
    initialState,
    reducers: {
        addToOtherWorspaces: (state, action: PayloadAction<WorkspaceContextDetails>) => {
            if (state.otherWorkspaces.findIndex(ws => ws.name === action.payload.name) === -1) {
                state.otherWorkspaces.push(action.payload);
                console.log("addToOtherWorspaces","otherWorkspaces",action.payload,state.otherWorkspaces);
            }
        },
        resetWorkspaceContext: (state) => {
            state = initialState;
        },
        setCurrentWorkspace: (state, action: PayloadAction<WorkspaceContextDetails>) => {
            console.log("Setting Current WS - setCurrentWorkspace",action.payload);
            state.currentWorkspace = action.payload;

            const currentMode = state.userModes.find((mode) => mode.workspace === action.payload.name);
            if (currentMode) {
                console.log(`workspaceSlice, setCurrentWorkspace currentUserMode -> to`,state.currentUserMode,currentMode);
                state.currentUserMode = currentMode;                
            }
        },
        setCurrentUserMode: (state, action: PayloadAction<string>) => {            
            state.currentUserMode = { ...state.currentUserMode, mode: action.payload };
            console.log(`workspaceSlice, setCurrentUserMode  currentUserMode : `,state.currentUserMode);
        },
        setCurrentUserStatus: (state, action: PayloadAction<string>) => {
            state.currentUserMode = { ...state.currentUserMode, status: action.payload };
            console.log(`workspaceSlice, setCurrentUserStatus  currentUserMode : `,state.currentUserMode);
        },
        setCurrentUserStatusOnline: (state) => {
            const currentMode = state.currentUserMode;
            currentMode.status = AgentStatus.online.toString();
            state.currentUserMode = currentMode;
            console.log(`workspaceSlice, setCurrentUserStatusOnline  currentUserMode : `,state.currentUserMode);
        },
        setOtherWorkspaces: (state, action: PayloadAction<WorkspaceContextDetails[]>) => {
            console.log("setOtherWorkspaces","otherWorkspaces",action.payload);
            state.otherWorkspaces = action.payload;
        },
        setPendingInvitations: (state, action: PayloadAction<Invitation[]>) => {
            state.pendingInvitations = action.payload;
        },
        setUserModes: (state, action: PayloadAction<UserMode[]>) => {
            state.userModes = action.payload;
        },
        switchWorkspace_old: (state, action: PayloadAction<string>) => {
            const currentWSName = action.payload;

           /*  let previousWSName = state.currentWorkspace.name;
            let previousModeIndex = state.userModes.findIndex((mode) => mode.workspace === previousWSName);
            if (previousModeIndex !== -1) {
                let previousMode = state.userModes[previousModeIndex];
                previousMode.status = 'offline';
                state.userModes.splice(previousModeIndex, 1, previousMode);
            }
            let newOtherWSlist = state.otherWorkspaces.filter((ows) => ows.name !== currentWSName);
            newOtherWSlist.push(state.currentWorkspace); */

            console.log("Setting Current WS - switchWorkspace",action.payload);
            const currentWS = state.otherWorkspaces.find((ows) => ows.name === currentWSName);
            state.currentWorkspace = currentWS ?? state.currentWorkspace;
            //state.otherWorkspaces = newOtherWSlist;
            const currentMode = state.userModes.find((mode) => mode.workspace === currentWSName);
            if (currentMode) {
                currentMode.status = AgentStatus.offline.toString();
                state.currentUserMode = currentMode;
                console.log(`workspaceSlice, switchWorkspace_old  currentUserMode : `,state.currentUserMode);
            }
        },
        switchWorkspace: (state, action: PayloadAction<WorkspaceContextDetails>) => {
            
            console.log("Setting Current WS - switchWorkspace",action.payload);
            state.currentWorkspace = action.payload;
            //state.otherWorkspaces = newOtherWSlist;
            const currentMode = state.userModes.find((mode) => mode.workspace === action.payload.name);
            if (currentMode) {
                currentMode.status = AgentStatus.offline.toString();
                state.currentUserMode = currentMode;
                console.log(`workspaceSlice, switchWorkspace  currentUserMode : `,state.currentUserMode);
            }
        },
        removeFromOtherWorspaces: (state, action: PayloadAction<string>) => {
            console.log("removeFromOtherWorspaces","otherWorkspaces",action.payload);
            state.otherWorkspaces = state.otherWorkspaces.filter((ows) => ows.name !== action.payload);
        },
        removeFromPendingInvitations: (state, action: PayloadAction<string>) => {
            state.pendingInvitations = state.pendingInvitations.filter((i) => i.workspace !== action.payload);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(initializeConsoleStatusAsync.fulfilled, (state, action: PayloadAction<InitialConsoleStatusDetails>) => {
            console.log(action.payload);
            console.log("Setting Current WS - initializeConsoleStatusAsync",action.payload);
            state.currentWorkspace = action.payload.currentWorkspace;
            const currentMode = action.payload.userModes.find((mode) => mode.workspace === action.payload.currentWorkspace.name);
            if (currentMode) {
                state.currentUserMode = currentMode;
                console.log(`workspaceSlice, initializeConsoleStatusAsync  currentUserMode :`,state.currentUserMode);
            }
            console.log("initializeConsoleStatusAsync","otherWorkspaces",action.payload.otherWorkspaces);
            state.otherWorkspaces = action.payload.otherWorkspaces;
            state.pendingInvitations = action.payload.pendingInvitations;
            state.userModes = action.payload.userModes;
            state.profile = action.payload.profile;
            
        })
        builder.addCase(fetchWorkspaceDetails.fulfilled, (state, action: PayloadAction<(Workspace | undefined)[]|undefined>) => {
            try {
                if(action.payload){
                    state.otherWorkspaces = state.otherWorkspaces.map((w)=>{
                        const temp = action.payload?.find((i)=> i?.key === `WS#${w.name}`);
                        if(temp){
                            w.avatar = temp.avatar ?? `http://${aws_config.bucket.image_bucket_name}.s3.${aws_config.bucket.image_bucket_region}.amazonaws.com/avatar/${w.name}`;
                            if(`WS#${state.currentWorkspace.name}` === temp.key){
                                const tempWs = state.currentWorkspace;
                                tempWs.avatar = temp.avatar ?? `http://${aws_config.bucket.image_bucket_name}.s3.${aws_config.bucket.image_bucket_region}.amazonaws.com/avatar/${w.name}`;
                                state.currentWorkspace = tempWs;
                            }
                        }
                        
                        return w;
                    });
                }               
                
            } catch (error) {
                console.error("workspaceSlice","fetchWorkspaceDetails",error);
            }
        })
        builder.addCase(setUserModeAsync.fulfilled, (state, action: PayloadAction<SetModeMutationVariables|undefined>) => {
                //state.currentUserMode = { ...state.currentUserMode, mode: action.payload.mode,status: action.payload.status };
                if(action.payload){
                    const setStatusVarible = action.payload;
                    if (setStatusVarible.status) {
                        state.currentUserMode = { ...state.currentUserMode, status: setStatusVarible.status };
                    }
                    else if (setStatusVarible.mode) {
                        state.currentUserMode = { ...state.currentUserMode, mode: setStatusVarible.mode };
                    }
                    console.log(`workspaceSlice, setUserModeAsync  currentUserMode : `,state.currentUserMode);
                }
                
        })
        builder.addCase(setUserModeAsync.rejected, (state, action) => {
            //state.currentUserMode = { ...state.currentUserMode };
            message.error(`Fail to set agent status To ${action.meta.arg.status}, mode to ${action.meta.arg.mode}`);
            console.error(`Fail to set agent status To ${action.meta.arg.status}, mode to ${action.meta.arg.mode}, state.currentUserMode : ${state.currentUserMode.status}/${state.currentUserMode.mode}`);
        })   
    }
})

export const {
    addToOtherWorspaces,
    resetWorkspaceContext,
    setCurrentWorkspace,
    setCurrentUserMode,
    setCurrentUserStatus,
    setCurrentUserStatusOnline,
    setOtherWorkspaces,
    setPendingInvitations,
    setUserModes,
    switchWorkspace,
    removeFromOtherWorspaces,
    removeFromPendingInvitations
} = workspaceSlice.actions

export default workspaceSlice.reducer