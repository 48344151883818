/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { CustomerDetail, Interaction } from "../../API";
import { ProfileLoadAction } from "../../constants/enum";
import { ProfileSearch } from "../../constants/types";
import { FileService } from "../../services/fileService";
import { InteractionService } from "../../services/InteractionService";
import { loadCustomerProfiles, onInteractionInboxLoaded } from "./customerDetailsSlice";

export type InteractionInboxLoadDetails = {
    workspace: string;
    nextToken?: string;
    limit?: number;
    serachText?: string;
};

export type InteractionInboxListItem = {
    groupedByKey: string;
    isFirstInGroup: boolean;
    interaction: Interaction;
    customerProfile?:CustomerDetail
}

export type InteractionInboxTimeSearch = {
    timeFrom?: string;
    timeTo?: string;
}

export interface InteractioninboxState {
    interactionsHandledByMe:  { [key: string]: InteractionInboxListItem }; // InteractionInboxListItem[];
    searchedInteractionsHandledByMe: InteractionInboxListItem[];
    selectedInteraction?: InteractionInboxListItem;
    searchText?: string;
    timeFrom?: string;
    timeTo?: string;
    searchType?: string;
    nextToken?: string;
    signedRecordingUrl?: string;
}

const initialState: InteractioninboxState = {
    interactionsHandledByMe: {},
    searchedInteractionsHandledByMe: []
};



    export const loadInteractionsInbox = createAsyncThunk<any, any>(
        'interactionInbox/loadInteractionsInbox', async (interactionLoadDetails: InteractionInboxLoadDetails, thunkApi) => {
            let keys:ProfileSearch[] = [];
            try {
                const storeState: any = thunkApi.getState();
                const interactionInboxState:InteractioninboxState = storeState.interactionInbox;
                const interactionConnector = await InteractionService.getInteractionsHandledByMe(
                interactionLoadDetails.workspace, 20,
                interactionLoadDetails.nextToken,
                interactionInboxState.searchType,
                interactionInboxState.timeFrom,
                interactionInboxState.timeTo);

                const nextToken = interactionConnector.nextToken ?? undefined;
                const interactionsHandledByme: Interaction[] = interactionConnector.items?.filter((item): item is Interaction => !!item)??[];
                const groupedInteractions = interactionGroupByCreatedDate(interactionsHandledByme);
                console.log("Grouped", interactionsHandledByme);
                console.log("Grouped", groupedInteractions);
                const currentList = interactionInboxState.interactionsHandledByMe ;
                const tempInteractionInboxItems: InteractionInboxListItem[] = getGroupedInteractionList(groupedInteractions, Object.values(currentList));
                
                keys = tempInteractionInboxItems.map(person => ({ key: person.interaction.customer??"", tempKey: person.interaction.id }));
                return {data: tempInteractionInboxItems,nextToken};
            } catch (error) {
                console.error("error occurred when loading interactions handled by me", error);
                return thunkApi.rejectWithValue('Error! GetInteractionsHandledByMe')
            }
            finally{
                setTimeout(()=>{
                    thunkApi.dispatch(loadCustomerProfiles({keys,workspace:interactionLoadDetails.workspace,profileLoadAction:ProfileLoadAction.interactionBox}));
                  },500); 
            }
        });

export const onSelectCdrLoad = createAsyncThunk<any, any>(
    'interactionInbox/onSelectInteractionsInbox', async (interactionInboxListItem: InteractionInboxListItem, thunkApi) => {
        try {
            console.log("onSelectInteractionsInbox", interactionInboxListItem);
            const response = await InteractionService.getCdrEntry(interactionInboxListItem.interaction.sessionId, interactionInboxListItem.interaction.workspace);
            console.log(response);
            const signedUrlData = await FileService.getUploadUrl("get", "mp3",
                interactionInboxListItem.interaction?.to?.display ?? '',
                interactionInboxListItem.interaction?.lastHandledBy?.id ?? '',
                "callRecordings", interactionInboxListItem.interaction.workspace, FileService.getRecordingKey(response?.recordingKey));
            return signedUrlData;
        } catch (error) {
            console.error("error occurred onSelectInteractionsInbox", error);
            return thunkApi.rejectWithValue('Error! onSelectInteractionsInbox')
        }
    });

    export   const fetchUserById = createAsyncThunk('interactionInbox/fetchUserById', (arg: any, thunkApi) =>{
        console.error("af");
    });

export const interactionsInboxSlice = createSlice({
    name: 'interactionInbox',
    initialState,
    reducers: {
        onInteractionInboxSelect: (state, action: PayloadAction<InteractionInboxListItem>) => {
            state.signedRecordingUrl = undefined;
            state.selectedInteraction = action.payload;
        },
        onInteractionInboxSearchTextChange: (state, action: PayloadAction<string>) => {
            state.searchText = action.payload;
            const searchResult = searchInteractions(action.payload, Object.values(state.interactionsHandledByMe));
            state.searchedInteractionsHandledByMe = searchResult;
        },
        onIntercationInboxTypeChange: (state, action: PayloadAction<string>) => {
            state.searchType = action.payload;
        },
        onIntercationInboxDateChange: (state, action: PayloadAction<InteractionInboxTimeSearch>) => {
            state.timeFrom = action.payload.timeFrom;
            state.timeTo = action.payload.timeTo;
        },
        clearInteractionInboxList: (state) => {
            state.selectedInteraction = undefined;
            state.searchedInteractionsHandledByMe = [];
            state.interactionsHandledByMe = {};
        },
        onInteractionInboxProfileLoaded: (state, action: PayloadAction<(CustomerDetail & ProfileSearch)[]>) => {
            try {
                action.payload.forEach((p)=>{
                    const temp = state.interactionsHandledByMe[p.tempKey];
                    if(temp){
                        state.interactionsHandledByMe[p.tempKey] = {...temp,customerProfile:p};
                    }
                });
                state.searchedInteractionsHandledByMe = searchInteractions(state.searchText ?? '', Object.values(state.interactionsHandledByMe));
            // eslint-disable-next-line no-empty
            } catch (error) {
                
            }
        },
    },
    extraReducers: (builder) => {
        builder            
            .addCase(loadInteractionsInbox.fulfilled, (state, action: PayloadAction<{data:InteractionInboxListItem[],nextToken:string}>) => {
                state.nextToken = action.payload.nextToken ?? undefined;
                action.payload.data.forEach(tempInteraction => {
                    state.interactionsHandledByMe[tempInteraction.interaction.id] = tempInteraction; //{item.interaction.id}
                });
                state.searchedInteractionsHandledByMe = searchInteractions(state.searchText ?? '', Object.values(state.interactionsHandledByMe));
            })
            .addCase(onSelectCdrLoad.fulfilled, (state, action) => {
                const signedUrlData = JSON.parse(action.payload);
                console.log("signedRecordingUrlFullFilled", signedUrlData?.data?.url);
                state.signedRecordingUrl = signedUrlData?.data?.url;
            })
    }
});

const interactionGroupByCreatedDate = (interactions: Interaction[]) => {
    return interactions.reduce((result: any, interaction: Interaction) => {
        const createdAtKey = moment(interaction.createdAt).format("YYYY_MM_DD") as string;
        (result[createdAtKey] = result[createdAtKey] || []).push(interaction);
        return result;
    }, {});
};

const getGroupedInteractionList = (groupedInteractions: any, currentList: InteractionInboxListItem[]) => {
    let tempInteractionInboxItems: InteractionInboxListItem[] = [];
    Object.keys(groupedInteractions).forEach(key => {
        const interactionsInGroup: Interaction[] = groupedInteractions[key];
        if (currentList.some(i => i.groupedByKey === key)) { //If the same date items already exists in state
            const items = interactionsInGroup.map((interaction: Interaction) => {
                const interactionInboxItem: InteractionInboxListItem = {
                    groupedByKey: key,
                    interaction: interaction,
                    isFirstInGroup: false
                };
                return interactionInboxItem;
            });
            tempInteractionInboxItems = tempInteractionInboxItems.concat(items);
        }
        else { // if not exists in state
            const items = groupedInteractions[key].map((interaction: Interaction, index: number) => {
                const interactionInboxItem: InteractionInboxListItem = {
                    groupedByKey: key,
                    interaction: interaction,
                    isFirstInGroup: index === 0 // Identify the first item
                };
                return interactionInboxItem;
            });
            tempInteractionInboxItems = tempInteractionInboxItems.concat(items);
        }
    });
    console.log("FinalList", tempInteractionInboxItems);
    console.log("FinalList", tempInteractionInboxItems.length);
    return tempInteractionInboxItems;
}

const searchInteractions = (searchString: string, interactionInboxItems: InteractionInboxListItem[]) => {
    if (searchString === '' || searchString === null) {
        return interactionInboxItems;
    }
    else {
        const searchedInteractions: InteractionInboxListItem[] = [];
        searchString = searchString.toLocaleLowerCase();
        interactionInboxItems.forEach(item => {
            if (item.interaction.customer?.toLocaleLowerCase().includes(searchString)) {
                searchedInteractions.push(item);
            }
            else if (item.interaction.from?.display?.toLocaleLowerCase().includes(searchString)) {
                searchedInteractions.push(item);
            }
            else if (item.interaction.type?.toLocaleLowerCase().includes(searchString)) {
                searchedInteractions.push(item);
            }
        });
        return searchedInteractions;
    }

}

export const { onInteractionInboxSelect, onInteractionInboxSearchTextChange, onIntercationInboxTypeChange,
    clearInteractionInboxList, onIntercationInboxDateChange ,onInteractionInboxProfileLoaded} = interactionsInboxSlice.actions;

export default interactionsInboxSlice.reducer;