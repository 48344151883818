import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ContactDirectoryItem } from "../../API";


export interface CustomerState {
  contactDirectoryItem?:ContactDirectoryItem,
  searchText:string
}

const initialState: CustomerState = {
  searchText: ""
};

export const contactDirectorySlice = createSlice({
  name: "contactDirectory",
  initialState,
  reducers: {   
    onSlecteContactDirectoryItem: (state, action: PayloadAction<ContactDirectoryItem>) => {
      state.contactDirectoryItem = action.payload;
    },
    onContactSearch:(state, action: PayloadAction<string>)=>{
      state.searchText = action.payload;
    }
  }
});

export const {  onSlecteContactDirectoryItem,onContactSearch } = contactDirectorySlice.actions;

export default contactDirectorySlice.reducer;
