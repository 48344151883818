/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {API} from "@aws-amplify/api";
import { ConversationParticipant, GetOngoingConversationsQueryVariables, GetRecentConversationsQueryVariables } from "../API";
import * as queries from "../graphql/queries";
import { store } from "../redux/store";

export const conversationService = {
    getOngoingConversations,
    getPastConversations
}

async function getOngoingConversations(type:string):Promise<ConversationParticipant[]|undefined> {
    try {
        const workspaceState = store?.getState()?.workspace;
        const conversationsState = store?.getState()?.conversations;
        const getOngoingConversationsQueryVariables: GetOngoingConversationsQueryVariables = {
            workspace: workspaceState?.currentWorkspace?.name,
            type,
            limit: 100,
            nextToken: conversationsState.ongoingConversationNextToken
        }
        const result = await API.graphql({ query: queries.getOngoingConversations, variables: getOngoingConversationsQueryVariables }) as any;
        console.log(result);
        const conversations = result.data.getOngoingConversations.items as ConversationParticipant[];
        console.log(conversations);
        //store.dispatch(setOngoingConversations(conversations));
        return conversations;
    }
    catch (error) {
        console.error("conversationService", "getOngoingConversations", error);
        return undefined;
    }
}

async function getPastConversations():Promise<ConversationParticipant[]|undefined> {
    try {
        const workspaceState = store?.getState()?.workspace;
        const conversationsState = store?.getState()?.conversations;
        const getRecentConversationsQueryVariables: GetRecentConversationsQueryVariables = {
            workspace: workspaceState?.currentWorkspace?.name,
            type: "interaction",
            limit: 2,
            nextToken: conversationsState.control.nextToken
        }
        const result = await API.graphql({ query: queries.getRecentConversations, variables: getRecentConversationsQueryVariables }) as any;
        const conversations = result.data.getRecentConversations.items as ConversationParticipant[];
         return conversations;
    }
    catch (error) {
        console.error("conversationService", "getPastConversations", error);
        return undefined;
    }
}