import React , {Suspense} from 'react';
// import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import awsExports from "./aws-exports";
import { Amplify, Auth } from 'aws-amplify';
import { CookieStorage } from 'amazon-cognito-identity-js';
//Amplify.configure(awsExports);
import { store } from './redux/store';
import { Provider } from 'react-redux';
import { AmplifyProvider, Authenticator } from "@aws-amplify/ui-react";
import { Loading } from './modules/home/Loading';
import {createRoot } from 'react-dom/client'

const App = React.lazy(() => import('./App'));

console.debug("------------------------------ * INDEX - rendering * ------------------------------");

console.log("%c ▒ - ☼ ___  welcome to dial desk! ___ ☼ - ▒", "text-transform: uppercase;font:bold 50px Tahoma, Geneva, sans-serif;font-style:normal;color:#a0d25f;background:#363533;border:3px dashed #72ae23;text-shadow:0px -1px 0px #0d0d0d;box-shadow:0px 0px 5px #333;-moz-box-shadow:0px 0px 5px #333;-webkit-box-shadow:0px 0px 5px #333;border-radius:15px 15px 15px 15px;-moz-border-radius:15px 15px 15px 15px;-webkit-border-radius:15px 15px 15px 15px;width:161px;padding:20px 45px;cursor:pointer;margin:0 auto;");
Amplify.configure(Object.assign({
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  graphql_headers: async () => ({
    'Authorization': (await Auth.currentSession()).getIdToken().getJwtToken(),
  }),
  storage: getDomain(),
}, awsExports));

function getDomain(): CookieStorage | undefined {
  if (process.env.REACT_APP_COOKIE_DOMAIN) {
    return new CookieStorage({
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      secure: false
    })
  }
}
// const container = document.getElementById('root');
// const root = createRoot(container!);
 const root = createRoot(document.getElementById('root')as HTMLElement);
 root.render(
  // root.render(
  <Provider store={store}>
    <React.StrictMode>
      <AmplifyProvider>
        <Authenticator.Provider>
          <Suspense fallback={<Loading name={'INDEX'}/>} >
            <App />
          </Suspense>
        </Authenticator.Provider>
      </AmplifyProvider>
    </React.StrictMode>
  </Provider>,
  // document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
