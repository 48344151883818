/* eslint-disable @typescript-eslint/no-explicit-any */
import { API } from "aws-amplify";
import { SetTrialPackageMutationVariables, Workspace ,Response, UpdateStripeProductQuantityMutationVariables} from "../API";
import { setTrialPackage, updateStripeProductQuantity } from "../graphql/mutations";
import { getWorkspace } from "../graphql/queries";

export class WorkspaceService {
    
    static getWorkspace = async (workspace:string) => {
      try {
        const response = await API.graphql({ query: getWorkspace, variables: {workspace} }) as any;
        return response.data.getWorkspace as Workspace;     
      } catch (error:any) {
          console.error("WorkspaceService", "getWorkspace", error);
          return undefined;
      }
    };

    static setTrialPackage  = async (workspace: string): Promise<boolean> => {
      try {        
        const setTrialPackageMutationVariables: SetTrialPackageMutationVariables = {
          provider: "",
          workspace
        };
        const interaction = await API.graphql({ query: setTrialPackage , variables: setTrialPackageMutationVariables }) as any;
        const response = interaction.data.setTrialPackage as Response;
        return response.status;
      } catch (error:any) {
          console.error("WorkspaceService", "setTrialPackage ", error);
          return false;
      }
    };

    static updateStripeProductQuantity  = async (workspace: string,type:string): Promise<boolean> => {
      try {        
        const updateStripeProductQuantityMutationVariables: UpdateStripeProductQuantityMutationVariables = {
          provider: "",
          type,
          workspace
        };
        const interaction = await API.graphql({ query: updateStripeProductQuantity , variables: updateStripeProductQuantityMutationVariables }) as any;
        const response = interaction.data.setTrialPackage as Response;
        return response.status;
      } catch (error:any) {
          console.error("WorkspaceService", "updateStripeProductQuantity ", error);
          return false;
      }
    };
}