/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { json } from "stream/consumers";
import { Message } from "../../API";
import { ChatTypes, MessageStatus } from "../../constants/enum";
import { TempChatMessage } from "../../constants/interfaces";





export interface userMessageState {
  tempMessage : {[key: string]: TempChatMessage[]} ;
}

const initialState: userMessageState = {
  tempMessage: {}
};

export const userMessageSlice = createSlice({
  name: "userMessage",
  initialState,
  reducers: {    
    onMessageAdded: (state, action: PayloadAction<TempChatMessage>) => {
      try {
        const message = action.payload;        
        if(state.tempMessage[message.to.id]){
          const temp = state.tempMessage[message.to.id];
          temp.splice(0, 0, {...message, updatedAt:Date.now()});
          //temp.push({...message});
          state.tempMessage[message.to.id] = [...temp]; //.sort((a,b)=> a.updatedAt-b.updatedAt)
        }else{
          state.tempMessage[message.to.id] = [{...message, updatedAt:Date.now()}];
        }
      } catch (error) {
        console.error("redux engine - onMessageAdded",error);
      }
      finally{
        console.debug("onMessageAdded",JSON.stringify(state.tempMessage));
      }
      
    },
    onMessageDelivered: (state, action: PayloadAction<{message:TempChatMessage,oldId:string}>) => {
      try {
        const message = action.payload;    
        const temp = state.tempMessage[message.message.to.id];    
        if(temp){        
          const removeIndex = temp.map((item)=>{ return item?.messageId; }).indexOf(message.oldId);
          if(removeIndex >= 0){            
            temp[removeIndex] = message.message;
            state.tempMessage[message.message.to.id] = [...temp];            
          }          
        }        
      } catch (error) {
        console.error("redux engine - onMessageDelivered",error);
      }
      finally{
        console.debug("onMessageDelivered",JSON.stringify(state.tempMessage));
      }   
    },
    onResetDeliveredMessage: (state, action:PayloadAction<{id:string | undefined}>) => {
      try {
        const id = action.payload.id;  
        if(id){
          const temp = state.tempMessage[id]; 
          if(temp){
            delete state.tempMessage[id];
          }
        }    
      } catch (error) {
        console.error("redux engine - onResetDeliveredMessage",error);
      }
    },
    onRemoveTempMessage: (state, action:PayloadAction<{id:string | undefined}>) => {
      try {
        const id = action.payload.id;  
        if(id){
          const temp = state.tempMessage[id]; 
          if(temp){
            delete state.tempMessage[id];
          }
        }    
      } catch (error) {
        console.error("redux engine - onRemoveTempMessage",error);
      }
    },
    onMessageSubscription: (state, action: PayloadAction<{message:TempChatMessage,selectedConversationFromId: string | undefined}>) => {
      try {

        const message = action.payload.message;  
        if(action.payload.selectedConversationFromId === message.from.id){
          if(state.tempMessage[message.from.id]){
            const temp = state.tempMessage[message.from.id];
            temp.splice(0, 0, {...message, updatedAt:Date.now()});
            //temp.push({...message, updatedAt:Date.now()});
            state.tempMessage[message.from.id] = [...temp];
          }else{
            state.tempMessage[message.from.id] = [{...message , updatedAt:Date.now()}];
          }
        }
      } catch (error) {
        console.error("redux engine - onMessageSubscription",error);
      } 
      finally{
        console.debug("onMessageSubscription",JSON.stringify(state.tempMessage));
      }     
    },
  },
});

export const { onMessageAdded ,onMessageDelivered,onResetDeliveredMessage,onMessageSubscription,onRemoveTempMessage} = userMessageSlice.actions;
export default userMessageSlice.reducer;
