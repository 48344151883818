/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { API } from "aws-amplify";
import { CdrEntry, CreateInteractionMutationVariables, CreateInteractionNoteMutationVariables, CustomerContactsInput, EndInteractionMutationVariables, GetInteractionDetailsQueryVariables, GetInteractionHandledByMeWithTypeTimeQueryVariables, GetInteractionOwnedByQueryVariables, GetInteractionQueryVariables, GetInteractionSessionQueryVariables, GetMySessionsQueryVariables, Interaction, InteractionConnector, InteractionDetails, InteractionInput, InteractionSession, InteractionSessionConnector, MoveInteractionMutationVariables, Note } from "../API";
import { getCdrEntry, getInteraction, getInteractionDetails, getInteractionHandledByMeWithTypeTime, getInteractionOwnedBy, getInteractionSession, getMySessions } from "../graphql/queries";
import { createInteraction, createInteractionNote, endInteraction, moveInteraction } from "../graphql/mutations";

export class InteractionService {

    
    static getInteractionSession = async (workspace: string, sessionId: string): Promise<InteractionSession | undefined> => {
        try {
            const getInteractionSessionQueryVariables: GetInteractionSessionQueryVariables = {
                sessionId,
                workspace
            };
            const interaction = await API.graphql({ query: getInteractionSession, variables: getInteractionSessionQueryVariables }) as any;
            return interaction.data.getInteractionSession as InteractionSession;
        } catch (error) {
            console.error("InteractionService", "getInteractionSession", error);
            return undefined;
        }
    }

    static getInteractionById = async (workspace: string, id: string): Promise<Interaction | undefined> => {
        try {
            const getInteractionQueryVariables: GetInteractionQueryVariables = {
                id,
                workspace
            };
            const interaction = await API.graphql({ query: getInteraction, variables: getInteractionQueryVariables }) as any;
            return interaction.data.getInteraction as Interaction;
        } catch (error) {
            console.error("InteractionService", "getInteractionById", error);
            return undefined;
        }
    }

    static moveInteraction = async (sessionId: string, to: string, workspace: string, contact: CustomerContactsInput | undefined): Promise<Interaction | undefined> => {
        try {
            /*  moveInteraction(sessionId: "25141b70-058a-4a9c-8a31-96b88dc68b52", to: "sukitha@duosoftware.com", workspace: "", contact: {contact: "1001", isPrimary: true, type: "mobile"}) {
                 Interaction_Customer_Index
                 Interaction_Handled_By_Index
                 Interaction_Time_Index
               } */
            const getInteractionQueryVariables: MoveInteractionMutationVariables = {
                sessionId,
                to,
                workspace,
                contact
            };
            const interaction = await API.graphql({ query: moveInteraction, variables: getInteractionQueryVariables }) as any;
            return interaction.data.moveInteraction as Interaction;
        } catch (error) {
            console.error("InteractionService", "moveInteraction", error);
            return undefined;
        }
    }

    static getInteractionOwnedBy = async (customer: string, workspace: string): Promise<(Interaction | null)[] | null | undefined> => {
        try {
            const getInteractionOwnedByQueryVariables: GetInteractionOwnedByQueryVariables = {
                customer,
                workspace,
            };
            const interactionsData = await API.graphql({ query: getInteractionOwnedBy, variables: getInteractionOwnedByQueryVariables }) as any;
            return interactionsData.items;
        } catch (error) {
            console.error("InteractionService", "getInteractionOwnedBy", error);
            return null;
        }
    }

    static getInteractionDetails = async (id: string | undefined, workspace: string): Promise<InteractionDetails | undefined> => {
        try {
            if (id === undefined) {
                return undefined;
            }
            const getInteractionDetailsQueryVariables: GetInteractionDetailsQueryVariables = {
                id,
                workspace
            };
            const interactionsData = await API.graphql({ query: getInteractionDetails, variables: getInteractionDetailsQueryVariables }) as any;
            return interactionsData?.data?.getInteractionDetails as InteractionDetails;
        } catch (error: any) {
            console.error("InteractionService", "getInteractionDetails", error);
            return error.data?.getInteractionDetails as InteractionDetails
        }
    }
    static getCdrEntry = async (cdrEntryId: string | undefined, workspace: string): Promise<CdrEntry | undefined> => {
        try {
            if (cdrEntryId === undefined) {
                return undefined;
            }
            const interactionsData = await API.graphql({ query: getCdrEntry, variables: { cdrEntryId, workspace } }) as any;
            return interactionsData?.data?.getCdrEntry as CdrEntry;
        } catch (error: any) {
            console.error("InteractionService", "getInteractionDetails", error);
            return error.data?.getCdrEntry as CdrEntry
        }
    }

    static createInteraction = async (interaction: InteractionInput, workspace: string): Promise<Interaction | undefined> => {
        try {
            const createInteractionMutationVariables: CreateInteractionMutationVariables = {
                workspace,
                interaction
            };
            const response = await API.graphql({ query: createInteraction, variables: createInteractionMutationVariables }) as any;
            return response.data.createInteraction as Interaction;
        } catch (error) {
            console.error("InteractionService", "createInteraction", error);
            return undefined;
        }
    }

    static endInteraction = async (interactionId: string, workspace: string): Promise<boolean> => {
        try {
            const endInteractionMutationVariables: EndInteractionMutationVariables = {
                interactionId,
                workspace
            };
            await API.graphql({ query: endInteraction, variables: endInteractionMutationVariables }) as any;
            return true;
        } catch (error) {
            console.error("InteractionService", "endInteraction", error);
            return false;
        }
    }
    static getMySessions = async (workspace: string): Promise<InteractionSession[] | undefined> => {
        let sessionConnector: InteractionSessionConnector = {
            __typename: "InteractionSessionConnector"
        };
        try {
            const getMySessionsQueryVariables: GetMySessionsQueryVariables = {
                workspace
            };
            const response = await API.graphql({ query: getMySessions, variables: getMySessionsQueryVariables }) as any;
            sessionConnector = response.data.getMySessions as InteractionSessionConnector;
        } catch (error: any) {
            console.error("InteractionService", "getMySessions", error);
            sessionConnector = error.data?.getMySessions as InteractionSessionConnector;
        }
        finally {
            const temp: InteractionSession[] = [];
            sessionConnector.items?.forEach((i) => {
                if (i) {
                    temp.push(i)
                }
            })
            // eslint-disable-next-line no-unsafe-finally
            return temp;
        }
    }

    static createInteractionNote = async (interactionId: string, message: string, workspace: string) => {
        try {
            const createInteractionNoteMutationVariables: CreateInteractionNoteMutationVariables = {
                interactionId,
                workspace,
                note: { message }
            }
            const result = await API.graphql({ query: createInteractionNote, variables: createInteractionNoteMutationVariables }) as any;
            const customer = result.data.createInteractionNote as Note;
            return customer;
        }
        catch (error: any) {
            console.error("customerService", "createInteractionNote", error);
            return error.data.createInteractionNote as Note
        }
    }

    static getInteractionsHandledByMe = async (workspace: string, limit?: number, nextToken?: string,
        type?: string, timeFrom?: string, timeTo?: string) => {
        try {
            const getInteractionHandledByMeQueryVariables: GetInteractionHandledByMeWithTypeTimeQueryVariables = {
                limit,
                nextToken,
                workspace,
                timeFrom,
                timeTo,
                type
            };
            const result = await API.graphql({ query: getInteractionHandledByMeWithTypeTime, variables: getInteractionHandledByMeQueryVariables }) as any;
            console.log("getInteractionsHandledByMe", result);
            
            const interactionDetails = result.data.getInteractionHandledByMeWithTypeTime as InteractionConnector;

            console.log("getInteractionsHandledByMe", interactionDetails);

            return interactionDetails;
        }
        catch (error: any) {
            console.error("interactionService", "getInteractionsHandledByMe", error);
            return error.data.getInteractionHandledByMeWithTypeTime as InteractionConnector
        }
    }
}