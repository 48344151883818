/* eslint-disable @typescript-eslint/ban-types */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CallOperationType, CallDirection, MediaType } from "../../constants/enum";
import { AgentService } from "../../services/agentService";
import {MyUserProfile, Number} from "../../API";
import { CallOperation } from "../../modules/phone/phoneSDK";
import { consoleMultipleColor } from "../../constants/constraints";

export interface callControllerState {
  remoteIdentity?: string;
  localIdentity?: string;
  reason?: string;
  status: string;
  direction?: CallDirection;
  interactionID?:string;
  operationStatus:CallOperation;
  notification?:CallOperation;
  acw:number
}


export interface MediaDetails {
  media: {
    localStream: MediaStream;
    remoteStream: MediaStream;
  };
  direction: CallDirection;
  mediaType: MediaType;
}

interface callDetails {
  call: callControllerState;
  control: {
    offline:boolean;
    idle: boolean;
    incomingCall: boolean;
    callRigging: boolean;
    disconnected: boolean;
    connected: boolean;
    remoteHold: boolean;
    remoteMute: {audio :boolean,video:boolean};
    localHold: boolean;
    localMute: {audio :boolean,video:boolean};
    transferType:CallOperationType;
    acw: boolean;
  };
  mediaDetails?: MediaDetails;
  fromNumbers:Number[],
  fromNumber:string,
  agentProfile: MyUserProfile|undefined,
  phoneEngineInvoker:Function,
  ongoingCallDuration:string
}

const initialState: callDetails = {
  call: {
    remoteIdentity: "",
    localIdentity: "",
    reason: "",
    status: "offline",
    direction: CallDirection.none,
    interactionID: "",
    operationStatus: { operation: CallOperationType.DEFAULT, status: true },
    acw: 0
  },
  control: {
    offline:true,
    idle: false,
    incomingCall: false,
    callRigging: false,
    disconnected: false,
    connected: false,
    remoteHold: false,
    remoteMute: { audio: false, video: false },
    localHold: false,
    localMute: { audio: false, video: false },
    transferType: CallOperationType.TRANSFER,
    acw: false
  },
  mediaDetails: undefined,
  fromNumbers: [],
  fromNumber: "NOT-SET",
  agentProfile: undefined,
  phoneEngineInvoker: () => console.log("%c Phone engine command sender function not overwrite - call controller",consoleMultipleColor),
  ongoingCallDuration:"00:00:00"
};

export const getMyNumbers = createAsyncThunk(
  "callControllerSlice/getMyNumbers",
  async (workspace: string /*thunkApi*/) => {//commented thunkApi becuase it never used
    try {
      console.debug("REDUX", "callController", "getMyNumbers");
      const response = await AgentService.getMyNumbers(workspace);
      if(response){
        return response.filter((item): item is Number => !!item);
      }
    } catch (error) {
      return undefined;
    }
  }
);

export const getAgentProfile = createAsyncThunk(
  "callControllerSlice/getAgentProfile",
  async () => {
    try {
      console.debug("REDUX", "callController", "getAgentProfile");
      return  await AgentService.getAgentProfile();      
    } catch (error) {
      return undefined;
    }
  }
);

export const callControllerSlice = createSlice({
  name: "callController",
  initialState,
  reducers: {
    onInvite: (state, action: PayloadAction<callControllerState>) => {
      console.debug("REDUX", "callController", "onInvite");
      state.call = { ...action.payload };
      state.control = {
        ...state.control,
        ...{ callRigging:true,idle: false, incomingCall: true, disconnected: false ,offline:false},
      };
    },  
    onCallRigging: (state, action: PayloadAction<callControllerState>) => {
      console.debug("REDUX", "callController", "onCallRigging");
      state.call = { ...action.payload };
      state.control = {
        ...state.control,
        ...{callRigging:true, idle: false, incomingCall: false, disconnected: false,offline:false },
      };
    },  
    onCallConnecting: (state, action: PayloadAction<callControllerState>) => {
      console.debug("REDUX", "callController", "onCallConnecting");
      state.call = {...state.call, ...action.payload };
      state.control = { ...state.control, ...{ incomingCall: false ,callRigging :false,offline:false } };
    },
    onCallConnected: (state, action: PayloadAction<callControllerState>) => {
      console.debug("REDUX", "callController", "onCallConnected");
      state.call = { ...state.call,...action.payload };
      state.control = { ...state.control, ...{ connected: true, incomingCall: false ,callRigging :false,offline:false} };
    },
    onCallDisconnected: (state, action: PayloadAction<callControllerState>) => {
      console.debug("REDUX", "callController", "onCallDisconnected");
      state.call = { ...state.call,...action.payload };
      state.control = {
        ...state.control,
        ...{ incomingCall: false, connected: false, disconnected: true  ,callRigging :false,localHold:false, acw:true,offline:false},
      };
    },
    onCallIdle: (state, action: PayloadAction<callControllerState>) => {
      console.debug("REDUX", "callController", "onCallIdle");
      state.call = { ...action.payload };
      state.control = {
        ...state.control,
        ...{
          idle: true,
          incomingCall: false,
          callRigging:false,
          disconnected: false,
          connected: false,
          remoteMute: {audio :false,video:false},
          remoteHold: false,
          localHold:false,
          acw:false,
          offline:false
        },
      };
    },
    onMediaUpdate: (state, action: PayloadAction<MediaDetails>) => {
      console.debug("REDUX", "callController", "onMediaUpdate");
      state.mediaDetails = { ...action.payload };
    },
    onCallHold: (state, action: PayloadAction<{originator:string}>) => {
      console.debug("REDUX", "callController", "onCallHold");
      if(action.payload.originator ==="remote"){
        state.control = { ...state.control, ...{ remoteHold: true } };
      }else{
        state.control = { ...state.control, ...{ localHold: true } };
      }      
    },
    onCallUnHold: (state, action: PayloadAction<{originator:string}>) => {
      console.debug("REDUX", "callController", "onCallUnHold");
      if(action.payload.originator ==="remote"){
        state.control = { ...state.control, ...{ remoteHold: false } };
      }else{
        state.control = { ...state.control, ...{ localHold: false } };
      }
    },
    onCallRemoteMute: (state, action: PayloadAction<callDetails>) => {
      console.debug("REDUX", "callController", "onCallRemoteMute");
      state.control.remoteMute= { ...action.payload.control.remoteMute };
    },
    onCallRemoteUnMute: (state, action: PayloadAction<callDetails>) => {
      console.debug("REDUX", "callController", "onCallRemoteUnMute");
      state.control.remoteMute= { ...action.payload.control.remoteMute };
    },
    onCallTransfer: (state, action: PayloadAction<{transferType:CallOperationType}>) => {
      console.debug("REDUX", "callController", "onCallTransfer");
      state.control.transferType= action.payload.transferType;
    },
    /* onCallOperationFail: (state, action: PayloadAction<{payload :{message:string,data:any}}>) => {
      state.call.failOperation = action.payload.payload;
    }, */
    onCallOperationStatus: (state, action: PayloadAction<CallOperation>) => {
      console.debug("REDUX", "callController", "onCallOperationStatus");
      state.call.operationStatus = action.payload;
      if(action.payload.status === false){
        state.control.transferType = action.payload.operation;
      }      
    },
    onNotification: (state, action: PayloadAction<CallOperation>) => {
      console.debug("REDUX", "callController", "onNotification");
      state.call.notification = {...action.payload,status:!state.call.notification?.status};           
    },
    onNotificationReset: (state/*, action*/) => {//commented this action becuase it never used
      console.debug("REDUX", "callController", "onNotificationReset");
      state.call.notification = undefined;  
    },
    onSetFromNumber: (state, action: PayloadAction<{fromNumber:string}>) => {
      console.debug("REDUX", "callController", "onSetFromNumber");
      state.fromNumber = action.payload.fromNumber;
    },
    onAcwElapsed: (state, action: PayloadAction<{acw:number}>) => {
      state.call.acw = action.payload.acw;
    },   
    onSetPhoneEngineInvoker:  (state, action: PayloadAction<Function>) => {
      state.phoneEngineInvoker = action.payload;
    },  
    onSetCallDuration:  (state, action: PayloadAction<string>) => {
      state.ongoingCallDuration = action.payload;
    },   
  },
  extraReducers: (builder) => {
    builder.addCase(getMyNumbers.fulfilled, (state, action: PayloadAction<Number[]|undefined>) => {
      if(action.payload){
        state.fromNumbers = action.payload;
        state.fromNumber =  action.payload[0]?.number;
      }      
    })
    builder.addCase(getAgentProfile.fulfilled, (state, action: PayloadAction<MyUserProfile|undefined>) => {
      try {
        if(action.payload){
          state.agentProfile = action.payload;
          const profile = action.payload;
          if(profile?.info?.metaData){
              const data = JSON.parse(JSON.parse(profile.info?.metaData??""));
              if(data){
                 state.fromNumber = data.CallSettings?.DefaultOutboundNumber??state.fromNumbers[0]?.number;                  
              }
          }   
        }
      } catch (error) {
          console.error("REDUX", "callController","getAgentProfile.fulfilled", error);
      }
    })
  }
});

export const {
  onInvite,
  onCallConnecting,
  onCallRigging,
  onCallConnected,
  onCallDisconnected,
  onCallIdle,
  onMediaUpdate,
  onCallHold,
  onCallUnHold,
  onCallRemoteMute,
  onCallRemoteUnMute,
  onCallTransfer,
  //onCallOperationFail,
  onCallOperationStatus,
  onSetFromNumber,
  onAcwElapsed,
  onSetPhoneEngineInvoker,
  onSetCallDuration,
  onNotification,
  onNotificationReset
} = callControllerSlice.actions;

export default callControllerSlice.reducer;
