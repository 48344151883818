/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RecentDial, RecentDialInput } from '../../API'
import { CallOperationStatus, CallType } from '../../constants/enum';
import ActivityService from '../../services/activityService';



export type UserStatus = {
  Name : string,
  Mode : string | null | undefined,
  UserType : string,
  src?:string
}

export interface recentActivityState {
  Calls :Array<RecentDial | null >,
  getRecentDialNextToken:string | null | undefined,
  RecentCalls: { [key: string]: {RecentDial:RecentDial , isSave:boolean} } 
}

const initialState: recentActivityState = {
  Calls: [],
  getRecentDialNextToken: null,
  RecentCalls: {}
}


export const getRecentDial = createAsyncThunk("recentActivitySlice/getRecentDial",async (args: { workspace: string; isInitialize?: boolean }, { getState }) => 
{
    const recentActivityState = (getState() as any)?.recentActivity as recentActivityState;
    type responseType = {
      status: boolean;
      items: (RecentDial | null)[];
      nextToken?: string | null;
      isInitialize?: boolean;
    };

    const reply: responseType = {status: false, items: [],nextToken: undefined,isInitialize: args.isInitialize,};

    try {
      const response = await ActivityService.getRecentDial(args.workspace,recentActivityState.getRecentDialNextToken);
      if (response) {
        return {...reply,status: true,items: response.items || [],nextToken: response.nextToken,};
      }
      return reply;
    } catch (error) {
      console.error("recentActivitySlice", "getRecentDial", error);
      return reply;
    }
  }
);

const saveRecentDial = async (workspace:string,recentDial:RecentDial)=>{
  try {     
    const data:RecentDialInput = {
      callerName: recentDial.callerName,
      contactUserType: recentDial.contactUserType,
      dialNumber: recentDial.dialNumber,
      operation: recentDial.operation,
      operationStatus: recentDial.operationStatus,
      recentDialId: recentDial.recentDialId,
      data:JSON.stringify({workspace})
    };
    return await ActivityService.addRecentDial(data,workspace);    
  }
  catch (error) {
      console.error("recentActivitySlice", "saveRecentDial", error);    
      return null;    
  }
}

export const addRecentDial = createAsyncThunk('recentActivitySlice/addRecentDial',async (args:{workspace:string,recentDial:RecentDialInput}) => 
{    
    type responseType = {
      status : boolean,
      data :RecentDial
    }

    const reply:responseType = {
      status: false,
      data: {
        __typename: "RecentDial",
        RecentDial_Handled_By_Index: "",
        RecentDial_Time_Index: "",
        callerName: args.recentDial.callerName,
        contactUserType: args.recentDial.contactUserType,
        createdAt: new Date().toISOString(),
        data: args.recentDial.data,
        dialNumber: args.recentDial.dialNumber,
        id: "",
        operation: args.recentDial.operation,
        operationStatus: args.recentDial.operationStatus,
        recentDialId: args.recentDial.recentDialId,
        workspace: args.workspace,
      }
    };

    try {      
      const response  = await ActivityService.addRecentDial(args.recentDial,args.workspace);
      if(response){
        return {...reply,status:true,data:response };
      }
      return reply;
    }
    catch (error) {
        console.error("recentActivitySlice", "addRecentDial", error);    
        return reply;    
    }
  }
);


export const recentActivitySlice = createSlice({
  name: 'RecentActivity',
  initialState,
  reducers: {    
    OnCallActivity: (state, action: PayloadAction<RecentDial>) => {      
      state.RecentCalls[action.payload.recentDialId] = {RecentDial:action.payload , isSave:false}  ;      
    },
    OnCallActivityUpdated: (state, action: PayloadAction<{recentDialId:string,callOperationStatus:CallOperationStatus}>) => {  
      const data = state.RecentCalls[action.payload.recentDialId] ;
      if(data.RecentDial.operationStatus !== CallOperationStatus.Outgoing){
        state.RecentCalls[action.payload.recentDialId] = {RecentDial:{...data.RecentDial,operationStatus:action.payload.callOperationStatus} , isSave:false} ;  
      }              
    },
    OnCallActivityCompleted :(state, action: PayloadAction<{recentDialId:string}>) => {      
      const recentDial = state.RecentCalls[action.payload.recentDialId] ;    
      recentDial && saveRecentDial(recentDial.RecentDial.workspace,{...recentDial.RecentDial});
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getRecentDial.fulfilled, (state, action) => {
        if(action.payload ){
          if(action.payload.isInitialize === true){
            state.Calls = [];
          }
          state.Calls = [...state.Calls,...action.payload.items];

          state.getRecentDialNextToken = action.payload.nextToken;          
        }          
    })
    .addCase(addRecentDial.fulfilled, (state, action) => {
      /* if(action.payload ){
        state.Calls.unshift(action.payload.data);
      }  */         
    })
  }
})

export const { OnCallActivity,OnCallActivityUpdated,OnCallActivityCompleted} = recentActivitySlice.actions

export default recentActivitySlice.reducer