/* eslint-disable @typescript-eslint/no-unused-vars */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AgentService } from '../../services/agentService'
import { onResetCustomerData, SelectedCustomer } from './customerDetailsSlice'
import { onInteractionSelected, onResetOngoingInteraction } from './interactionSlice'


export const fetchMyTeamsAll = createAsyncThunk(
  'agent/getMyTeamsAll',
  async (workspace: string, thunkAPI) => {
    const response = await AgentService.getMyTeamsAll(workspace)
    return response
  }
)

export const setSelectedAgent = createAsyncThunk('agent/setSelectedAgent', async (params:{selectedAgent:SelectedCustomer|undefined , needToReset:boolean},{dispatch}) => {
  if(params.needToReset === true && params.selectedAgent){
    await dispatch(onResetCustomerData());  
    await dispatch(onInteractionSelected({ sessionId: '-setSelectedAgent' }));
    await dispatch(onResetOngoingInteraction());
  }  
  return params.selectedAgent
});

export interface AgentState {
  myTeamNames: Array<string>,
  selectedAgent: SelectedCustomer | undefined;
}

const initialState: AgentState = {
  myTeamNames: [],
  selectedAgent: undefined
}

export const agentSlice = createSlice({
  name: 'agent',
  initialState,
  reducers: {    
    setSelectedAgentReset: (state, action: PayloadAction) => {
      state.selectedAgent = undefined;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMyTeamsAll.fulfilled, (state, action) => {
      state.myTeamNames = action.payload
    })
    .addCase(setSelectedAgent.fulfilled, (state, action: PayloadAction<SelectedCustomer|undefined>) => {
      state.selectedAgent = action.payload ?{ ...action.payload }:undefined;
    })
  },
})

export const { setSelectedAgentReset} = agentSlice.actions

export default agentSlice.reducer;

