/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { API } from "aws-amplify";
import { CustomerDetail, GetTeamsQueryVariables, GetUserModesQueryVariables, UserMode, UserModeConnector, WorkspaceTeam, WorkspaceTeamConnector } from "../API";
import { getAllFlows, getTeams, getUserModes } from "../graphql/queries";

export class UserService {
    
    
    static getUserModes = async (workspace:string): Promise<Array<UserMode | null>> => {
        let users:Array<UserMode | null > = [];
        try {
          
          const  loadData = async(nextToken:string|undefined)=>{
            const getUserModesQueryVariables: GetUserModesQueryVariables = {
              workspace,nextToken
            };
            const interaction = await API.graphql({ query: getUserModes, variables: getUserModesQueryVariables }) as any;
            const data = interaction.data.getUserModes as UserModeConnector;
            
            if(data.items){
              users = [...users,...data.items];
            }
            if(data.nextToken){
              loadData(data.nextToken);
            }
          }
          await loadData(undefined);          
        } catch (error) {
            console.error("UserService", "getUserModes", error);
        }
        finally{
          // eslint-disable-next-line no-unsafe-finally
          return users;
        }
    }

    static getAllFlows = async (workspace:string) => {
      try {
        const response = await API.graphql({ query: getAllFlows, variables: {workspace} }) as any;
        return response.data.getAllFlows ;     
      } catch (error:any) {
          console.error("NodeService", "getAllFlows", error);
          return error.data.getAllFlows
      }
    };

    static getTeams = async (workspace:string):Promise<WorkspaceTeam[]|undefined> => {
      try {
        const getTeamsQueryVariables:GetTeamsQueryVariables = { workspace ,limit:1000 };
        const response = await API.graphql({ query: getTeams, variables: getTeamsQueryVariables }) as any;
        const data =  response.data.getTeams as WorkspaceTeamConnector;     
        return data.items?.filter((item): item is WorkspaceTeam => !!item);
      } catch (error:any) {
          console.error("NodeService", "getAllFlows", error);
          return undefined
      }
    };

}