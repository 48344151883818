/* eslint-disable @typescript-eslint/no-explicit-any */
import { API, graphqlOperation } from "aws-amplify";
import { MyUserProfile } from "../../../API";
import { getMyUserProfile } from "../../../graphql/queries";

export const userService = {
    getMyProfile
}

let myProfile: MyUserProfile;

async function getMyProfile(forceRefreshProfile: boolean) {

    if (myProfile && !forceRefreshProfile) {
        return myProfile;
    }
    else {
        try {
            const myUserProfileResponse = (await API.graphql(
                graphqlOperation(getMyUserProfile)
            )) as any;

            myProfile = myUserProfileResponse.data.getMyUserProfile as MyUserProfile;
            return myProfile;
        }
        catch (error) {
            console.error("userService", "getMyProfile", error);
        }
    }
}