/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as queries from "../../graphql/queries";
import { API } from 'aws-amplify';
import { ConversationParticipant, CustomerDetail, GetRecentConversationsQueryVariables, Message, SubscriberMessage, SubscriberSessionEvent, SubscribeToConversationsSubscriptionVariables } from '../../API'
import { ChatTypes, InteractionTypes, MessageStatus, ProfileLoadAction } from '../../constants/enum';
import { conversationService } from '../../services/conversationService';
import { OngoingConversationParticipant, TempChatMessage } from '../../constants/interfaces';
import { onMessageSubscription, onRemoveTempMessage } from './userMessageSlice';
import { MessageService } from '../../services/messageService';
import { CustomerService } from '../../services/customerService';
import { PastConversations, ProfileSearch } from '../../constants/types';
import { loadCustomerProfiles } from './customerDetailsSlice';
import { v4 as uuidv4 } from "uuid";

type ConversationsControl = {  
  nextToken?: string | null,  
  hasMorePastConversations: boolean,
  newMessage:string
};

export interface ConversationsState {
  
  //ongoingConversations: OngoingConversationParticipant[];
  ongoingConversations: {[key: string]: OngoingConversationParticipant} ;
  pastConversations: {[key: string]: PastConversations} ; 
  ongoingConversationNextToken?: string;
  selectedConversation?: ConversationParticipant;
  selectedSubscribedConversation?: SubscriberSessionEvent;
  conversations: SubscribeToConversationsSubscriptionVariables,
  control:ConversationsControl
}

const initialState: ConversationsState = {
  pastConversations: {},
  conversations: {
    receiver: ''
  },
  control: {
    nextToken: undefined,
    hasMorePastConversations: true,
    newMessage:"init"
  },
  ongoingConversations: {}
}

export const getPastConversations = createAsyncThunk(
  'conversationsSlice/getPastConversations',
  async (args:{workspace:string,reset:boolean , type?:InteractionTypes},{ getState ,dispatch }) => {
    const conversationsState = (getState() as any)?.conversations as ConversationsState;
    const pastConversations:ConversationParticipant[] = [];
    const reply = {workspace:args.workspace,dispatch,reset :args.reset, nextToken:conversationsState.control.nextToken,pastConversations};
    try {          
      const getRecentConversationsQueryVariables: GetRecentConversationsQueryVariables = {
          workspace:args.workspace,
          type: args.type === InteractionTypes.agent ? "direct" : "interaction",
          limit: 100,
          nextToken: reply.nextToken
      }
      const result = await API.graphql({ query: queries.getRecentConversations, variables: getRecentConversationsQueryVariables }) as any;
      const conversations = result.data.getRecentConversations.items as ConversationParticipant[];
      reply.pastConversations = conversations;
      reply.nextToken = result.data.getRecentConversations.nextToken;

      const keys:ProfileSearch[]= conversations.map(i=> {return {key:i.conId,tempKey:i.conId}});
      dispatch(loadCustomerProfiles({keys,workspace:args.workspace,profileLoadAction:ProfileLoadAction.pastConversations}));
    }
    catch (error) {
        console.error("conversationService", "getPastConversations", error);        
    }
    finally{
      // eslint-disable-next-line no-unsafe-finally
      return reply;
    }
  }
);

export const onConversationTypeChanged = createAsyncThunk('conversationsSlice/onConversationTypeChanged',
  async (args:{workspace:string,reset:boolean , type:InteractionTypes},{ dispatch }) => {
    try {   
      if(args.reset === false){
        return undefined;
      }  
      console.info("ongoingConversations","onConversationTypeChanged-----------");

      if(args.type !== InteractionTypes.agent){
        dispatch(getPastConversations({workspace : args.workspace , reset:args.reset , type:args.type}));
      }else{
        dispatch(onLoadConversations({workspace : args.workspace , type:args.type}));
      }   
      /* dispatch(getPastConversations({workspace : args.workspace , reset:args.reset , type:args.type}));
      dispatch(onLoadConversations({workspace : args.workspace , type:args.type})); */
      //return await conversationService.getOngoingConversations(args.type === InteractionTypes.agent? "direct":"interaction") as OngoingConversationParticipant[];
    }
    catch (error) {
        console.error("conversationService", "onConversationTypeChanged", error);  
        return undefined;      
    }
  }
);

export const onLoadConversations = createAsyncThunk('conversationsSlice/onLoadConversations',
  async (args:{workspace:string, type?:InteractionTypes},{ dispatch }) => {
    try {   
      console.info("ongoingConversations","onLoadConversations-----------");
      //const data = await conversationService.getOngoingConversations(args.type === InteractionTypes.agent? "direct":"interaction") as OngoingConversationParticipant[];
      const data = await conversationService.getOngoingConversations("direct") as OngoingConversationParticipant[];
      const keys:ProfileSearch[]= data.map(i=> {return {key:i.conId,tempKey:i.conId}});
      dispatch(loadCustomerProfiles({keys,workspace:args.workspace,profileLoadAction:ProfileLoadAction.ongoingConversations}));
      return {data,workspace:args.workspace}
    }
    catch (error) {
        console.error("conversationService", "onLoadConversations", error);  
        return undefined;      
    }
  }
);

export const OnConversationsReceived = createAsyncThunk('conversationsSlice/OnConversationsReceived',
  async (subscriberMessage:SubscriberMessage,{ getState,dispatch }) => {
    try {   
      const conversationsState = (getState() as any)?.conversations as ConversationsState;
      const message:TempChatMessage = {
        contentType: subscriberMessage.contentType,
        from: subscriberMessage.from,
        message: subscriberMessage.message,
        to: subscriberMessage.to,
        type: subscriberMessage.type,
        workspace: subscriberMessage.workspace,
        conId: subscriberMessage.conId,
        messageId: subscriberMessage.messageId,
        messageStatus: MessageStatus.received,
        chatType: subscriberMessage.from.id.startsWith("client")? ChatTypes.Customer:ChatTypes.Direct,
        __typename: 'Message',
        updatedAt:Date.now()
      };
      const id = conversationsState.selectedConversation?.conId.replaceAll("member_","").replaceAll("::","").replaceAll(conversationsState.selectedConversation.partyId,"");
      dispatch(onMessageSubscription({message,selectedConversationFromId : id}));
      return subscriberMessage;
    }
    catch (error) {
        console.error("conversationService", "OnConversationsReceived", error);  
        return subscriberMessage;      
    }
  }
);

export const OnConversationsRead = createAsyncThunk('conversationsSlice/OnConversationsRead',
  async (message:Message,{dispatch}) => {
    try {   
      await MessageService.updateMessageStatus(message,MessageStatus.seen);
      return message;
    }
    catch (error) {
        console.error("conversationService", "OnConversationsRead", error);  
        return message;      
    }
  }
);

export const setSelectedConversation = createAsyncThunk('conversationsSlice/setSelectedConversation',
  async (message:PastConversations,{dispatch}) => {
    try {   
      const id = message?.conId?.replaceAll("member_","")?.replaceAll("::","")?.replaceAll(message.partyId,"");
      dispatch(onRemoveTempMessage({id}));
      return message;
    }
    catch (error) {
        console.error("conversationService", "setSelectedConversation", error);  
        return message;      
    }
  }
);

export const conversationsSlice = createSlice({
  name: 'conversations',
  initialState,
  reducers: {
    OnMessageRead: (state, action: PayloadAction<OngoingConversationParticipant>) => {
      const temp_conversations = state.ongoingConversations[action.payload.conId];
      if(temp_conversations){        
        temp_conversations.unreadCount = 0;
        temp_conversations.hasNewMessage = false;
        state.ongoingConversations[temp_conversations.conId] =  temp_conversations;
      }
    },
    OnConversationsReceived_old: (state, action: PayloadAction<SubscriberMessage>) => {
      state.conversations = {receiver:action.payload.conId??"NOTSET"};

      const temp_conversations = state.ongoingConversations[action.payload.conId??"NOTSET"];
      const msg:OngoingConversationParticipant = temp_conversations ? {...temp_conversations} : {
        hasNewMessage: false,
        unreadCount: 0,
        __typename: 'ConversationParticipant',
        conId: action.payload.conId??"NOTSET",
        entry: action.payload.type,
        isActive: false,
        key: action.payload.messageId??action.payload.to.id,
        partyId: action.payload.from.id,
        partyType: action.payload.type
      }
      if(msg){
        msg.hasNewMessage = true;
        msg.unreadCount = msg.unreadCount && msg.unreadCount > 0 ? (msg.unreadCount + 1) : 0;
        state.ongoingConversations[msg.conId] = msg;
      }   
      
    },    
    addToPastConversations: (state, action: PayloadAction<PastConversations>) => {
      try {          
          console.warn("conversationsSlice", "addToPastConversations",action.payload);
          state.pastConversations[action.payload.conId] = action.payload;
      } catch (error) {
        console.error("conversationsSlice", "addToPastConversations", error);     
      }      
    },    
    setSelectedSubscribedConversation: (state, action: PayloadAction<SubscriberSessionEvent>) => {
      state.selectedSubscribedConversation = action.payload;
    },
    resetToDefault: (state, action: PayloadAction<boolean>) => {
      console.warn("conversationsSlice", "resetToDefault");
      state.pastConversations = {};
      state.conversations = { receiver: '' };
      state.control = {nextToken: undefined,hasMorePastConversations: true ,newMessage:"reset"};
      state.ongoingConversations = {};
    },
    OnMapProfileToConversation: (state, action: PayloadAction<{data:{[key: string]: CustomerDetail},profileLoadAction:ProfileLoadAction}>) => {
      try {
        console.warn("conversationsSlice", "OnMapProfileToConversation",action.payload);
        if(action.payload?.data){          
          Object.entries(action.payload.data).forEach(([key,value])=>{
              if(action.payload.profileLoadAction === ProfileLoadAction.pastConversations){
                const temp = state.pastConversations[key];
                if(temp){
                  state.pastConversations[key] = {...temp,customerProfile:value};
                }
              }else if(action.payload.profileLoadAction === ProfileLoadAction.ongoingConversations){
                const temp = state.ongoingConversations[key];
                if(temp){
                  state.ongoingConversations[key] = {...temp,customerProfile:value};
                }
              }    
          });
        }
      // eslint-disable-next-line no-empty
      } catch (error) {
        
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPastConversations.fulfilled, (state, action) => {
        console.warn("conversationsSlice", "getPastConversations",action.payload);
        if(action.payload){
          state.control.nextToken = action.payload.nextToken; 
          state.control.hasMorePastConversations = action.payload.nextToken? true:false;
          if(action.payload.reset){
            state.pastConversations = {};
          }
          const keys:string[]=[];
          action.payload.pastConversations?.forEach(convo => {
            state.pastConversations[convo.conId] = convo;
            keys.push(convo.conId);            
          });          
        }          
    })
    .addCase(onConversationTypeChanged.fulfilled, (state, action:PayloadAction<OngoingConversationParticipant[]|undefined>) => {
      state.ongoingConversations = {};
      if(action.payload){
        const temp :{[key: string]: OngoingConversationParticipant} = {};
        action.payload.forEach((c)=>{
          temp[c.conId] = c;
        });
        state.ongoingConversations =  temp;
        console.info("ongoingConversations","onConversationTypeChanged.fulfilled",temp);
      }          
    })
    .addCase(onLoadConversations.fulfilled, (state, action:PayloadAction<any>) => {
      //{data,workspace:args.workspace,dispatch}
      if(action.payload?.data){
        const temp :{[key: string]: OngoingConversationParticipant} = {};
        action.payload.data.forEach((c:OngoingConversationParticipant)=>{
          temp[c.conId] = c;
        });
        state.ongoingConversations =  temp; 
        console.info("ongoingConversations","onLoadConversations.fulfilled",temp);   
      }          
    })
    .addCase(OnConversationsReceived.fulfilled, (state, action:PayloadAction<SubscriberMessage>) => {
      if(action.payload){
        state.conversations = {receiver:action.payload.conId??"NOTSET"};
        const temp_conversations = state.ongoingConversations[action.payload.conId??"NOTSET"];
        const msg:OngoingConversationParticipant = temp_conversations ? {...temp_conversations} : {
          hasNewMessage: false,
          unreadCount: 0,
          __typename: 'ConversationParticipant',
          conId: action.payload.conId??"NOTSET",
          entry: action.payload.type,
          isActive: false,
          key: action.payload.messageId??action.payload.to.id,
          partyId: action.payload.from.id,
          partyType: action.payload.type
        }
        if(msg){
          msg.hasNewMessage = true;
          msg.unreadCount = msg.unreadCount? msg.unreadCount+1 : 1;
          state.ongoingConversations[msg.conId] = msg;
          state.control.newMessage = uuidv4();
        }   
      }          
    })
    .addCase(OnConversationsRead.fulfilled, (state, action:PayloadAction<Message>) => {
      if(action.payload){
        const temp_conversations = state.ongoingConversations[action.payload.conId??""];      
        if(temp_conversations){
          temp_conversations.hasNewMessage = false;
          temp_conversations.unreadCount = 0;
          state.ongoingConversations[temp_conversations.conId] = temp_conversations;
        } 
      }          
    })
    .addCase(setSelectedConversation.fulfilled, (state, action:PayloadAction<PastConversations>) => {
      state.selectedConversation = action.payload;
      const temp_conversations = state.ongoingConversations[action.payload.conId??""];      
        if(temp_conversations){
          temp_conversations.hasNewMessage = false;
          temp_conversations.unreadCount = 0;
          //state.ongoingConversations[temp_conversations.conId] = temp_conversations;
        } 
    })
  }
})

export const {
  OnConversationsReceived_old,  
  addToPastConversations,
  setSelectedSubscribedConversation ,
  resetToDefault,
  OnMapProfileToConversation
} = conversationsSlice.actions

export default conversationsSlice.reducer


