import React from "react";
import { createContext } from "react";
import { AgentMode, AgentStatus } from "./enum";
import { AgentContextInterface, PhoneStateContextInterface } from "./interfaces";

export const consoleMultipleColor = 'font-weight: bold; font-size: 50px;color: red; text-shadow: 3px 3px 0 rgb(217,31,38) , 6px 6px 0 rgb(226,91,14) , 9px 9px 0 rgb(245,221,8) , 12px 12px 0 rgb(5,148,68) , 15px 15px 0 rgb(2,135,206) , 18px 18px 0 rgb(4,77,145) , 21px 21px 0 rgb(42,21,113)';

export const consoleDotBox =  "text-transform: uppercase;font:bold 24px Tahoma, Geneva, sans-serif;font-style:normal;color:#d1be60;background:#363533;border:3px dashed #d1be60;text-shadow:0px -1px 0px #0d0d0d;box-shadow:0px 0px 5px #333;-moz-box-shadow:0px 0px 5px #333;-webkit-box-shadow:0px 0px 5px #333;border-radius:15px 15px 15px 15px;-moz-border-radius:15px 15px 15px 15px;-webkit-border-radius:15px 15px 15px 15px;width:77px;padding:20px 45px;cursor:pointer;margin:0 auto;";

export const consoleDotBoxGreen =  "font:bold 24px Tahoma, Geneva, sans-serif;font-style:normal;color:#67e06b;background:#363533;border:3px dashed #67e06b;text-shadow:0px -1px 0px #0d0d0d;box-shadow:0px 0px 5px #333;-moz-box-shadow:0px 0px 5px #333;-webkit-box-shadow:0px 0px 5px #333;border-radius:15px 15px 15px 15px;-moz-border-radius:15px 15px 15px 15px;-webkit-border-radius:15px 15px 15px 15px;width:77px;padding:20px 45px;cursor:pointer;margin:0 auto;";

export const consoleDotBoxRed =  "font:bold 24px Tahoma, Geneva, sans-serif;font-style:normal;color:#f52b2b;background:#363533;border:3px dashed #f52b2b;text-shadow:0px -1px 0px #0d0d0d;box-shadow:0px 0px 5px #333;-moz-box-shadow:0px 0px 5px #333;-webkit-box-shadow:0px 0px 5px #333;border-radius:15px 15px 15px 15px;-moz-border-radius:15px 15px 15px 15px;-webkit-border-radius:15px 15px 15px 15px;width:77px;padding:20px 45px;cursor:pointer;margin:0 auto;";

export const consoleDotBoxYellow =  "font:bold 14px Tahoma, Geneva, sans-serif;font-style:normal;color:yellow;background:#363533;border:3px dashed #f52b2b;text-shadow:0px -1px 0px #0d0d0d;box-shadow:0px 0px 5px #333;-moz-box-shadow:0px 0px 5px #333;-webkit-box-shadow:0px 0px 5px #333;border-radius:15px 15px 15px 15px;-moz-border-radius:15px 15px 15px 15px;-webkit-border-radius:15px 15px 15px 15px;width:77px;padding:20px 45px;cursor:pointer;margin:0 auto;";

export const consoleDotBoxBlack =  "font:bold 12px Tahoma, Geneva, sans-serif;font-style:normal;color:#fff;background:#444;border:2px dashed #f52b2b;text-shadow:0px -1px 0px #0d0d0d;box-shadow:0px 0px 5px #333;-moz-box-shadow:0px 0px 5px #333;-webkit-box-shadow:0px 0px 5px #333;border-radius:15px 15px 15px 15px;-moz-border-radius:15px 15px 15px 15px;-webkit-border-radius:15px 15px 15px 15px;width:30px;padding:20px 20px;cursor:pointer;margin:0 auto;";

export const consoleLargeTextRed =  "color:red;font-family:system-ui;font-size:1.5rem;-webkit-text-stroke: 1px black;font-weight:bold";

export const consoleAlteringBox = "display: inline-block ; border: 3px solid red ; border-radius: 7px ; padding: 10px ; margin: 20px ;";

export const consoleLargeTextBlue =  "color:blue;font-family:system-ui;font-size:1rem;-webkit-text-stroke: 1px black;font-weight:bold";

export const consoleLargeTextBlack =  "display: inline-block ; border: 1px solid green ;border-radius: 7px ; padding: 5px ; margin: 5px ;color:#fff;background:#363533;font-family:system-ui;font-size:1rem;-webkit-text-stroke: 1px black;font-weight:bold";
export const consoleBoxTextRed =  "display: inline-block ; border: 1px solid green ;border-radius: 7px ; padding: 5px ; margin: 5px ;color:red;background:#363533;font-family:system-ui;font-size:1rem;-webkit-text-stroke: 1px black;font-weight:bold";

export const statusMap = {
    "offline":{status : AgentStatus.offline , reason : "logoff"},
    "idle_login":{status : AgentStatus.online , reason : "login"},
    "idle_breakend":{status : AgentStatus.online , reason : "breakend"},
    "idle_acwend":{status : AgentStatus.online , reason : "acwend"},
    "idle_userchange":{status : AgentStatus.online , reason : "userchange"},
    "idle_UserSelected":{status : AgentStatus.online , reason : "User Selected"},
    "acw_inbound":{status : AgentStatus.acw , reason : "interaction:callend:inbound"},
    "acw_outbound":{status : AgentStatus.acw , reason : "interaction:callend:outbound"},
    "busy_inbound":{status : AgentStatus.busy , reason : "interaction:call:inbound"},
    "busy_outbound":{status : AgentStatus.busy , reason : "interaction:call:outbound"},
    "break":{status : AgentStatus.break , reason : "****"},
    "online_inbound":{status : AgentStatus.online , reason : "login" ,mode : AgentMode.inbound},
    "online_outbound":{status : AgentStatus.online , reason : "login" ,mode : AgentMode.outbound},
    "donotdisturb_inbound":{status : AgentStatus.offline , reason : "donotdisturbend" ,mode : AgentMode.inbound},
    "donotdisturb_outbound":{status : AgentStatus.offline , reason : "donotdisturbend" ,mode : AgentMode.outbound},
    "break_inbound":{status : AgentStatus.break , reason : "break:inbound" ,mode : AgentMode.inbound},
    "break_outbound":{status : AgentStatus.break , reason : "break:outbound" ,mode : AgentMode.outbound},
    "offline_inbound":{status : AgentStatus.offline , reason : "logoff" ,mode : AgentMode.inbound},
    "offline_outbound":{status : AgentStatus.offline , reason : "logoff" ,mode : AgentMode.outbound},    
};

/* export const PhoneContext_DEFAULT_VALUE = {
    send: ()=>console.log('%c Phone engine SEND not overwrite!', consoleMultipleColor),
    setSend: () => { },
  };

export const PhoneStateContext = React.createContext<PhoneStateContextInterface>(PhoneContext_DEFAULT_VALUE); */
export const AgentContext = createContext<AgentContextInterface|undefined>(undefined);


export const URL_DEFAULT_VALUE = {   
    SendCommand:()=>console.log("%c Phone engine command sender function not overwrite",consoleMultipleColor),
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    updateSendCommand: () => { },
};  
  
export const PhoneStateContext = React.createContext<PhoneStateContextInterface>(URL_DEFAULT_VALUE);
