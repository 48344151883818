/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Action,
  ThunkAction,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import counterReducer from "./slice/counterSlice";
import workspaceSlice from "./slice/workspaceSlice";
import notificationSlice from "./slice/notificationSlice";
import messageStatusState from "./slice/messageStatusSlice";
import conversationsSlice from "./slice/conversationsSlice";
import phoneControllerSlice from "./slice/phoneControllerSlice";
import callControllerSlice from "./slice/callControllerSlice";
import interactionsSlice from "./slice/interactionSlice";
import customerDetailsSlice from "./slice/customerDetailsSlice";
import userMessageSlice from "./slice/userMessageSlice";
import interactionsInboxSlice from "./slice/interactionInboxSlice";
import { useDispatch } from "react-redux";
import recentActivitySlice from "./slice/recentActivitySlice";
import contactDirectorySlice from "./slice/contactDirectorySlice";
import agentSlice from "./slice/agentSlice";
import authSlice from "./slice/authSlice";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    workspace: workspaceSlice,
    notification: notificationSlice,
    messageStatus: messageStatusState,
    conversations: conversationsSlice,
    phoneController: phoneControllerSlice,
    callController: callControllerSlice,
    interaction: interactionsSlice,
    customerDetails: customerDetailsSlice,
    userMessage: userMessageSlice,
    interactionInbox: interactionsInboxSlice,
    recentActivity: recentActivitySlice,
    contactDirectorySlice: contactDirectorySlice,
    agent: agentSlice,
    auth: authSlice,
  },
  // middleware: [
  //   ...getDefaultMiddleware({
  //     serializableCheck: false,
  //   }),
  // ],
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
