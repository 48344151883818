/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Customer, CustomerDetail } from "../../API";
import { ProfileLoadAction } from "../../constants/enum";
import { ProfileSearch } from "../../constants/types";
import { CustomerService } from "../../services/customerService";
import { setSelectedAgentReset } from "./agentSlice";
import { OnMapProfileToConversation } from "./conversationsSlice";
import { onInteractionInboxProfileLoaded } from "./interactionInboxSlice";
import { OnOngoingInteractionProfileLoaded } from "./interactionSlice";

export type CustomerSearchInteractionInfo = {
  sessionId: string;
  fromId: string;
}

export interface SelectedCustomer { // #TODO remove this
  name: string;
  type?: string;
  convId: string;
  avatar?: string;
}
export interface CustomerState {
  selectedCustomer: SelectedCustomer | undefined; // #TODO remove this and use activeCustomer
  customer?: CustomerDetail;
  searchedCustomerList: Customer[];
  selectedSearchedCustomer?: Customer;
  customerSearchFieldType: string;
  customerSearchString: string;
  showCustomerSearch: boolean;
  customerSearchSelectedInteractionDetails?: CustomerSearchInteractionInfo;
  activeCustomer?:Customer,
  customerProfileList: { [key: string]: CustomerDetail };
}

export interface CustomerSearchDetails {
  workspace: string;
  searchString: string;
  searchType?: string;
}

const initialState: CustomerState = {
  selectedCustomer: undefined,
  searchedCustomerList: [],
  customerSearchFieldType: 'name',
  customerSearchString: '',
  showCustomerSearch: false,
  customerProfileList:{}
};

export const searchCustomersAsync = createAsyncThunk(
  "customer/searchCustomersAsync",
  async (workspace: string, thunkApi) => {
    const storeState: any = thunkApi.getState();
    const customerDetailState: CustomerState = storeState.customerDetails;
    if (customerDetailState.customerSearchString.length > 2) {
      const customerSearchDetails: CustomerSearchDetails = {
        searchString: customerDetailState.customerSearchString,
        workspace: workspace,
        searchType: customerDetailState.customerSearchFieldType
      };
      const searchedCustomers = await CustomerService.searchCustomers(customerSearchDetails);
      console.log("CustomerSearch", searchedCustomers);
      return searchedCustomers;
    }
    else {
      return [];
    }
  }
);
//SelectedCustomer|undefined
export const setSelectedCustomer = createAsyncThunk('customer/setSelectedCustomer', async (params:SelectedCustomer|undefined,{dispatch}) => {
  if(params !== undefined){
    //await dispatch(setSelectedAgent({needToReset:true,selectedAgent:undefined}));  
    await dispatch(setSelectedAgentReset());
    //await dispatch(onInteractionSelected({ sessionId: '-setSelectedCustomer' }));
  }  
  return params
});

export const loadCustomerProfiles = createAsyncThunk('customer/loadCustomerProfiles', async (params:{keys:ProfileSearch[],workspace:string,profileLoadAction:ProfileLoadAction},{getState,dispatch}) => {
  
  try {   
    if(params.keys.length === 0 ){
      return undefined;  
    } 
    const temp:(CustomerDetail & ProfileSearch)[] = [];
    const customerDetails = (getState() as any)?.customerDetails as CustomerState;
    const profiles = {...customerDetails?.customerProfileList};
      const func_array:any[] = [];
      params.keys?.forEach((p)=>{
        if(p.key.toLowerCase() !== "unknown" && p.key !== ""){
          if(!profiles[p.key]){
            func_array.push(CustomerService.getCustomerProfile(p.key?.replaceAll("client_",""), params.workspace,p.key,p.tempKey));
          } else{          
            const key = (params.profileLoadAction === ProfileLoadAction.ongoingConversations ||params.profileLoadAction === ProfileLoadAction.pastConversations)? p.key : (p.tempKey);
            temp.push({...profiles[p.key],tempKey:key??p.key,key:p.key});
          }  
        }
             
      });
      const data = await Promise.all<((CustomerDetail & ProfileSearch) | undefined)[]>(func_array);
      
      if(data){
        data.forEach((item)=>{
          if(item){
            profiles[item.key] = item;
            temp.push({...item});
          }      
        });
      }
      switch (params.profileLoadAction){
        case ProfileLoadAction.pastConversations:
        case ProfileLoadAction.ongoingConversations:
          dispatch(OnMapProfileToConversation({data:profiles, profileLoadAction:params.profileLoadAction}));
          break;
        case ProfileLoadAction.ongoingInteractions:
          if(temp.length){
            dispatch(OnOngoingInteractionProfileLoaded(temp));
          }
          break;
        case ProfileLoadAction.interactionBox:
          dispatch(onInteractionInboxProfileLoaded(temp))
          break;
      }
      
      
      return profiles;
  } catch (error) {
    console.error("customerDetailsSlice","loadCustomerProfiles", error);  
    return undefined;  
  }
  
});


export const customerDetailsSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    setCustomerDetails: (state, action: PayloadAction<CustomerDetail>) => {
      state.customer = action.payload;
    },
    onResetCustomerData: (state) => {
      state.selectedCustomer = undefined;
    },
    setSearchedSelectedCustomer: (state, action: PayloadAction<Customer>) => {
      state.selectedSearchedCustomer = action.payload;
    },
    setCustomerSearchString: (state, action: PayloadAction<string>) => {
      state.customerSearchString = action.payload;
    },
    setCustomerSearchFieldType: (state, action: PayloadAction<string>) => {
      state.customerSearchFieldType = action.payload;
    },
    clearCustomerSearch: (state, action: PayloadAction<void>) => {
      state.searchedCustomerList = [];
    },
    clearSearchedSelectedCustomer: (state, action: PayloadAction<void>) => {
      state.selectedSearchedCustomer = undefined;
    },
    showCustomerSearch: (state, action: PayloadAction<CustomerSearchInteractionInfo>) => {
      state.showCustomerSearch = true;
      state.customerSearchSelectedInteractionDetails = action.payload;
    },
    hideCustomerSearch: (state) => {
      state.showCustomerSearch = false;
      state.customerSearchSelectedInteractionDetails = undefined;
    },
    onSelectCustomer : (state, action: PayloadAction<Customer>) => {
      state.activeCustomer = action.payload;
    },
    onProfileLoaded : (state, action: PayloadAction<{[key: string]: CustomerDetail}>) => {
      if(action.payload){
        Object.entries(action.payload).forEach(([key,value])=>{
          if(!state.customerProfileList[key]){
            state.customerProfileList[key] = value;
          }           
        })
      }
    },
    onInteractionInboxLoaded : (state, action: PayloadAction<any>) => {
      state.activeCustomer = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchCustomersAsync.fulfilled, (state, action: PayloadAction<Customer[]>) => {
      state.searchedCustomerList = action.payload;
      state.selectedSearchedCustomer = undefined;
    }).addCase(setSelectedCustomer.fulfilled, (state, action: PayloadAction<SelectedCustomer|undefined>) => {
      state.selectedCustomer = action.payload ?{ ...action.payload }:undefined;
    })
    .addCase(loadCustomerProfiles.fulfilled, (state, action: PayloadAction<{[key: string]: CustomerDetail}|undefined>) => {
        if(action.payload){
          Object.entries(action.payload).forEach(([key,value])=>{
            if(!state.customerProfileList[key]){
              state.customerProfileList[key] = value;
            }
          })
        }
    })
  }
});

export const { setCustomerDetails,  setSearchedSelectedCustomer, clearCustomerSearch,
  clearSearchedSelectedCustomer, setCustomerSearchString, setCustomerSearchFieldType,
  showCustomerSearch, hideCustomerSearch ,onSelectCustomer,onResetCustomerData,onProfileLoaded,onInteractionInboxLoaded } =
  customerDetailsSlice.actions;

export default customerDetailsSlice.reducer;
