/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Item from "antd/lib/list/Item";
import { CdrEntry, ConversationParticipant, Customer, CustomerDetail, Disposition, Interaction, InteractionDetails, InteractionSession, MessageInteraction, Note } from "../../API";
import { InteractionTypes, ProfileLoadAction } from "../../constants/enum";
import { OngoingInteractions, ProfileSearch } from "../../constants/types";
import { CustomerService } from "../../services/customerService";
import { FileService } from "../../services/fileService";
import { InteractionService } from "../../services/InteractionService";
import { setSelectedAgentReset } from "./agentSlice";
import { addToPastConversations, onConversationTypeChanged } from "./conversationsSlice";
import { loadCustomerProfiles, onProfileLoaded, onResetCustomerData, setSelectedCustomer } from "./customerDetailsSlice";

type loadInteractionsVariables = {
  unmappedInteractions: { [key: string]: InteractionSession };
  ongoingInteractions: { [key: string]: OngoingInteractions };
  status: boolean;
  workspace:string
};
export type InteractionEnd = {
  sessionId: string;
  workspace: string;
  customerProfile:CustomerDetail,
  partyId:string,
  convId:string
};


export type InteractionCDRDateGetDetails = {
  sessionId: string;
  workspace: string;
  display: string;
  handledBy: string;
};


export type UpdateInteractionDetails = InteractionDetails & {
  sessionId: string | undefined;
  showHideCallTag: boolean;
};

export interface InteractionsState {
  unmappedInteractions: { [key: string]: InteractionSession };
  ongoingInteractions: { [key: string]: OngoingInteractions };
  selectedInteraction?: InteractionSession;
  selectedOngoingInteraction?: OngoingInteractions;
  selectedPastInteraction?: ConversationParticipant;
  lastInteraction?: OngoingInteractions;
  selectedPastInteractionSessionId?: string;
  control: {
    
    selectedInteractionChanged: boolean;
    showProfileSelector:boolean;
    updateInteractionDetails?:UpdateInteractionDetails
  };
  selectedMessageInteraction: MessageInteraction | null | undefined;
  signedRecordingUrl?: string;
  cdr?: CdrEntry;
  interactionType:InteractionTypes
}

const initialState: InteractionsState = {
  unmappedInteractions: {},
  ongoingInteractions: {},
  selectedInteraction: undefined,
  control: {
    selectedInteractionChanged: false,
    showProfileSelector:false,
  },
  selectedMessageInteraction: undefined,
  interactionType: InteractionTypes.client
};


export const endInteraction = createAsyncThunk<any, any>(
  "interaction/endInteraction",
  async (interaction: InteractionEnd,{dispatch}) => {
    try {
      const response = await InteractionService.endInteraction(
        interaction.sessionId,
        interaction.workspace
      );
      if(response){
        const conversationParticipant:ConversationParticipant ={
          __typename: "ConversationParticipant",
          conId: interaction.convId,
          entry: `WS#${interaction.workspace}#CON#${interaction.convId}`,
          isActive: false,
          key: `WS#${interaction.workspace}#MEM#${interaction.customerProfile?.info?.name}`,
          partyType: "member",
          unreadCount:0,
          updatedAt:new Date(Date.now()).toISOString(),
          partyId:interaction.partyId??"NOT AVAILABLE"
        };
        
        await dispatch(addToPastConversations({...conversationParticipant,customerProfile:interaction.customerProfile}));
        
        //await dispatch(onInteractionSelected({ sessionId: '-setSelectedAgent' }));
        await dispatch(setSelectedAgentReset());
        await dispatch(onResetCustomerData());          
        await dispatch(onResetOngoingInteraction());
      }
      
      return { interaction, status: response };
    } catch (error) {
      console.error("error occurred when ending ongoing interactions", error);
      return { interaction, status: false };
    }
  }
);

export const loadInteractions = createAsyncThunk<
  // Return type of the payload creator
  loadInteractionsVariables,
  // First argument to the payload creator
  string,
  {
    // Optional fields for defining thunkApi field types
    rejectValue: string;
  }
>("interaction/loadInteractions", async (workspace, thunkApi) => {
  const keys:ProfileSearch[] = [];
  try {
    if (workspace === "") {
      return thunkApi.rejectWithValue("Validation error! invalid workspace");
    }
    const reply: loadInteractionsVariables = {
      unmappedInteractions: {},
      ongoingInteractions: {},
      status: false,
      workspace:workspace
    };
    
    let mappedInteractions: (Interaction | undefined)[] = [];
    let unmappedInteractions: (InteractionSession | undefined)[] = [];
    let unmappedSessionIds: (string | undefined)[] = [];
    const response = await InteractionService.getMySessions(workspace);
    if (response && response.length > 0) {
      const sessionIds = response.map((r) => r.sessionId);
      const func_array = [];
      for (const i of sessionIds) {
        func_array.push(InteractionService.getInteractionById(workspace, i));
      }
      const interactions = await Promise.all(func_array);
      if (interactions && interactions.length > 0) {
        mappedInteractions = interactions.filter((i) => {
          if (
            i?.Interaction_Customer_Index !== undefined &&
            i?.Interaction_Customer_Index !== null
          ) {
            return true;
          }
          return false;
        });
      }
      if (mappedInteractions && mappedInteractions.length > 0) {
        const mappedSessionIds = mappedInteractions.map((m) => m?.sessionId);
        unmappedSessionIds = sessionIds.filter(
          (val) => !mappedSessionIds.includes(val)
        );
      } else if (sessionIds && sessionIds.length > 0) {
        unmappedSessionIds = [...sessionIds];
      }

      if (unmappedSessionIds && unmappedSessionIds.length > 0) {
        const func_array = [];
        for (const i of unmappedSessionIds) {
          if (i) {
            func_array.push(
              InteractionService.getInteractionSession(workspace, i)
            );
          }
        }
        unmappedInteractions = await Promise.all(func_array);
      }

      mappedInteractions?.forEach((m) => {
        if (m?.sessionId) {
          const temp: any = { ...m };
          temp.convId = `client_${m.customer}`;
          temp.customerSuggestion = {
            __typename: "CustomerSuggestion",
            contact: m.from.id,
            customer: m.customer,
          };          
          reply.ongoingInteractions[m?.sessionId] = { ...temp };
          keys.push({key:temp.convId,tempKey:m?.sessionId});
        }
      });


      unmappedInteractions?.forEach((m) => {
        if (m?.sessionId) {
          reply.unmappedInteractions[m?.sessionId] = { ...m };
        }
      });
      reply.status = true;
      // The value we return becomes the `fulfilled` action payload
      return reply;
    } else {
      // For example we can return validation result here
      return thunkApi.rejectWithValue("Validation error! Response data was 5!");
    }
  } catch (error) {
    return thunkApi.rejectWithValue("Validation error! Response data was 5!");
  }finally{
    setTimeout(()=>{
      thunkApi.dispatch(loadCustomerProfiles({keys,workspace:workspace,profileLoadAction:ProfileLoadAction.ongoingInteractions}));
    },500);    
  }
});

export const onSelectInteractionCdrLoad = createAsyncThunk<any, any>(
    'interaction/onSelectInteractionCdrLoad', async (interactionCDRDataGetDetails: InteractionCDRDateGetDetails, thunkApi) => {
        try {
            console.log("onSelectInteractionCdrLoad", interactionCDRDataGetDetails);
            const cdrResponse = await InteractionService.getCdrEntry(interactionCDRDataGetDetails.sessionId, interactionCDRDataGetDetails.workspace);
            console.log("CdrResponse", cdrResponse);
            const signedUrlData = await FileService.getUploadUrl("get", "mp3",
                cdrResponse?.toDisplay ?? '',
                cdrResponse?.handledBy ?? '',
                "callRecordings", interactionCDRDataGetDetails.workspace, FileService.getRecordingKey(cdrResponse?.recordingKey));
            return {signedUrlData,cdrResponse};
        } catch (error) {
            console.error("error occurred onSelectInteractionCdrLoad", error);
            return thunkApi.rejectWithValue('Error! onSelectInteractionCdrLoad')
        }
});

export const onProfileMapToInteraction = createAsyncThunk<any, any>(
  'interaction/onProfileMapToInteraction', async (interaction: OngoingInteractions, {dispatch}) => {
      try {
          const customerProfile = await CustomerService.getCustomerProfile(interaction?.convId?.replaceAll("client_",""),interaction.workspace,interaction?.convId);
          dispatch(setSelectedCustomer({ convId: interaction?.convId, name:customerProfile?.info?.firstName? `${customerProfile?.info?.firstName} ${customerProfile?.info?.lastName}` :  interaction?.customerSuggestion?.customer ?? "XXXXXXXXXXXXXX" }));
          dispatch(onOngoingInteractionSelected({...interaction,customerProfile}));
          return {...interaction,customerProfile};
      } catch (error) {
          console.error("error occurred onProfileMapToInteraction", error);
          return {...interaction};
      }
});

export const onInteractionTypeChanged = createAsyncThunk<any, any>(
  'interaction/onInteractionTypeChanged', async (args : {interactionType: InteractionTypes,workspaceName:string,resetConversations:boolean}, {dispatch}) => {
      try {
        // no need to load data again. if new call or chat received its will automatically adding to list. if you load data again profile map need to be call
        //dispatch(onConversationTypeChanged({workspace : args.workspaceName , reset:args.resetConversations , type:args.interactionType}));       
      } catch (error) {
          console.error("error occurred onInteractionTypeChanged", error);          
      }
      return args.interactionType;
});

export const interactionsSlice = createSlice({
    name: 'interaction',
    initialState,
    reducers: {
        onInteraction: (state, action: PayloadAction<InteractionSession>) => {
            state.unmappedInteractions[action.payload.sessionId] = action.payload;
            delete state.ongoingInteractions[action.payload.sessionId];
        },        
        onInteractionSelected: (state, action: PayloadAction<{ sessionId: string }>) => {
            state.control.selectedInteractionChanged = !state.control.selectedInteractionChanged;
            state.selectedOngoingInteraction = undefined;
            state.selectedInteraction = undefined;
            state.selectedPastInteractionSessionId = undefined;
            state.selectedPastInteraction = undefined;
            state.selectedInteraction = state.unmappedInteractions[action.payload.sessionId];
            state.signedRecordingUrl = undefined;
        },
        onOngoingInteractionSelected: (state, action: PayloadAction<OngoingInteractions>) => {
            state.control.selectedInteractionChanged = !state.control.selectedInteractionChanged;
            state.selectedInteraction = undefined;
            state.selectedPastInteractionSessionId = undefined;
            state.selectedPastInteraction = undefined;
            state.selectedOngoingInteraction = action.payload;
            state.signedRecordingUrl = undefined;
            console.error("--------------------------------------------------------")
        },
        onPastInteractionSelected: (state, action: PayloadAction<ConversationParticipant>) => {
            state.signedRecordingUrl = undefined;
            state.control.selectedInteractionChanged = !state.control.selectedInteractionChanged;
            state.selectedInteraction = undefined;
            state.selectedPastInteractionSessionId = undefined;
            state.selectedOngoingInteraction = undefined;
            state.selectedPastInteraction = action.payload;

        },
        onLoadPastInteractionDetails: (state, action: PayloadAction<{ interaction: MessageInteraction | null | undefined }>) => {
            //state.control.selectedInteractionChanged = !state.control.selectedInteractionChanged;
            /*  state.selectedOngoingInteraction = undefined;
             state.selectedInteraction = undefined; */
            state.selectedMessageInteraction = action.payload.interaction;
            state.selectedPastInteractionSessionId = action.payload.interaction?.sessionId ?? "--xxxxx";
        },
        onShowHideCallTag: (state, action: PayloadAction<{ status: boolean, sessionId: string | undefined ,notes?:  Array<Note | null > | null,disposition?:  Array<Disposition | null > | null }>) => {
            state.control.updateInteractionDetails = {showHideCallTag : action.payload.status,sessionId : action.payload.sessionId ,notes: action.payload.notes, disposition: action.payload.disposition, __typename: "InteractionDetails"};
        },
        onResetOngoingInteraction :(state) => {
          state.selectedOngoingInteraction = undefined;
        },
        
        OnShowHideProfileSelector: (state, action: PayloadAction<boolean>) => {
          state.control.showProfileSelector = action.payload;
        },
        OnOngoingInteractionProfileLoaded: (state, action: PayloadAction<((CustomerDetail & ProfileSearch) | undefined)[]>) => {
          try {
            if(action.payload){
              action.payload.forEach((item:any)=>{
                const temp = state.ongoingInteractions[item.tempKey];
                if(temp){
                  state.ongoingInteractions[item.tempKey] = {...temp,customerProfile:item};
                }
              })
            }
          // eslint-disable-next-line no-empty
          } catch (error) {
            
          }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(endInteraction.fulfilled, (state, action) => {
            if (action.payload.status === true) {
                state.lastInteraction = { ...state.ongoingInteractions[action.payload.interaction.sessionId] };
                delete state.ongoingInteractions[action.payload.interaction.sessionId];
                state.selectedOngoingInteraction = undefined;
            }
        })
        .addCase(loadInteractions.fulfilled, (state, action) => {
                if (action.payload.status === true) {
                  Object.entries(action.payload.ongoingInteractions).forEach(([key,value])=>{
                    if(!state.ongoingInteractions[key]){
                      state.ongoingInteractions[key] = value;
                    }
                  });
                  Object.entries(action.payload.unmappedInteractions).forEach(([key,value])=>{
                    if(!state.unmappedInteractions[key]){
                      state.unmappedInteractions[key] = value;
                    }
                  });
                  //  state.ongoingInteractions = { ...state.ongoingInteractions, ...action.payload.ongoingInteractions };
                  //  state.unmappedInteractions = { ...state.unmappedInteractions, ...action.payload.unmappedInteractions };                    
                }
        })
        .addCase(onSelectInteractionCdrLoad.fulfilled, (state, action) => {
                state.cdr = action.payload.cdrResponse;
                const signedUrlData = JSON.parse(action.payload.signedUrlData);
                console.log("signedRecordingUrlFullFilled", signedUrlData?.data?.url);
                state.signedRecordingUrl = signedUrlData?.data?.url;
                
        })
        .addCase(onProfileMapToInteraction.fulfilled, (state, action: PayloadAction<OngoingInteractions>) => {
            if(action.payload){
              state.control.selectedInteractionChanged = !state.control.selectedInteractionChanged;
              state.selectedInteraction = undefined;
              state.ongoingInteractions[action.payload.sessionId] = action.payload;
              delete state.unmappedInteractions[action.payload.sessionId];
            }
        })
        .addCase(onInteractionTypeChanged.fulfilled, (state, action: PayloadAction<InteractionTypes>) => {
          state.interactionType = action.payload;
        })
    }
});



export const { OnOngoingInteractionProfileLoaded,onInteraction, onInteractionSelected, onLoadPastInteractionDetails, onShowHideCallTag, onOngoingInteractionSelected, onPastInteractionSelected ,onResetOngoingInteraction } = interactionsSlice.actions;

export default interactionsSlice.reducer;
