import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Notification, NotificationConnector, ReadNotificationMutationVariables, SubscriberSessionEvent } from '../../API';
import { notificationService } from '../../services/notificationService';


export interface INotificationDetails {
  allNotifications: Notification[];
  nextToken: string | null | undefined;
  subscribedConversations: {[key: string]: SubscriberSessionEvent};
}

const initialState: INotificationDetails = {
  allNotifications: [],
  nextToken: null,
  subscribedConversations: {},
}

export const getNotificationsAsync = createAsyncThunk(
  'notifications/getNotifications',
  async (currentWS: string) => {
    await notificationService.getAllNotifications(currentWS);
  }
);

export const markAsReadNotificationsAsync = createAsyncThunk(
  'notifications/markAsReadNotifications',
  async (readNotificationMutationVariables: ReadNotificationMutationVariables) => {
    await notificationService.deleteNotification(readNotificationMutationVariables);
  }
);

export const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    OnNotification: (state, action: PayloadAction<Notification>) => {
      const isExists = state.allNotifications.some(n => n.notificationId === action.payload?.notificationId);
      if (!isExists) {
        state.allNotifications.unshift(action.payload);
      }
    },
    setAllNotifications: (state, action: PayloadAction<NotificationConnector>) => {
      state.nextToken = action.payload.nextToken;
      const receivedNotifications: Notification[] = [];
      action.payload.items && action.payload.items.forEach((item) => {
        const isExists = state.allNotifications.some(n => n.notificationId === item?.notificationId);
        if (item !== null && !isExists) {
          receivedNotifications.push(item);
        }
      });
      // eslint-disable-next-line prefer-spread
      state.allNotifications.push.apply(state.allNotifications, receivedNotifications);
    },
    markAsReadNotification: (state, action: PayloadAction<string>) => {
      state.allNotifications = state.allNotifications.filter(n => n.notificationId !== action.payload);
    },
    onSubscribeToHandleSession: (state, action: PayloadAction<SubscriberSessionEvent>) => {
      //state.subscribedConversations.unshift(action.payload);
      state.subscribedConversations[action.payload.sessionId] = action.payload;
      
    },
  },
})

export const { OnNotification, setAllNotifications, markAsReadNotification,onSubscribeToHandleSession, } = notificationSlice.actions

export default notificationSlice.reducer