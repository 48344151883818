/* eslint-disable @typescript-eslint/no-explicit-any */
import { API } from "aws-amplify";
import { GetNotificationsQueryVariables, NotificationConnector, ReadNotificationMutationVariables } from "../API";
import { markAsReadNotification, setAllNotifications } from "../redux/slice/notificationSlice";
import { store } from "../redux/store";
import { getNotifications } from "../graphql/queries";
import { readNotification } from "../graphql/mutations";

export const notificationService = {
    getAllNotifications,
    deleteNotification
};


async function getAllNotifications(workspace: string) {
    const { notification } = store.getState();
    const getNotificationsQueryVariables: GetNotificationsQueryVariables = {
        workspace: workspace,
        nextToken: notification.nextToken
    };
    try {
        const notifications = await API.graphql({ query: getNotifications, variables: getNotificationsQueryVariables }) as any;
        store.dispatch(setAllNotifications(notifications.data.getNotifications as NotificationConnector));
    }
    catch (error) {
        console.error("notificationService", "getNotifications", error);
    }
}

async function deleteNotification(readNotificationMutationVariables: ReadNotificationMutationVariables) {
    try {
        await API.graphql({ query: readNotification, variables: readNotificationMutationVariables });
        store.dispatch(markAsReadNotification(readNotificationMutationVariables.notificationId));
    }
    catch (error) {
        console.error("notificationService", "deleteNotification", error);
    }
}